@media only screen and (min-width: 2560px) and (max-width: 5000px) {
  .pointsale_slider {
    width: 55%;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 2000px) {
  .pointsale_slider {
    width: 58%;
  }
}
@media only screen and (min-width: 1121px) and (max-width: 1279px) {
  .container {
    width: 98%;
  }
  h1 {
    font-size: 32px;
  }
  .Login .registration_head .btn-primary.login {
    margin: 20px 0 0;
  }

  .daterange .for_date {
    padding: 0 25px;
  }
  .progress {
    width: 44px;
  }
  .outof_point {
    width: 45px;
  }
  .sell_type {
    width: 70%;
  }
  .selling_list .sell_type ul li {
    padding: 0 12px;
  }
  .spent_des p {
    min-height: 70px;
  }

  .sale_search {
    width: 25%;
  }

  .pointsale_slider {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1120px) {
  .container {
    width: 98%;
  }

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 32px;
  }

  .main::before {
    width: 180px;
  }
  .main::after {
    width: 180px;
  }
  .login .registration_head .btn-primary.login {
    margin: 20px 0 0;
  }
  .registration_con {
    padding: 40px 0 0;
  }
  .registration_main::before {
    width: 220px;
  }
  .registr_slider::before {
    width: 250px;
    left: -120px;
  }

  body.closed #header {
    transform: translateY(-70px);
  }
  .header_top {
    padding: 0;
    height: 70px;
  }
  .logo {
    width: 120px;
    line-height: 70px;
  }
  .header_right ul li a {
    line-height: 70px;
  }
  .header_right .launch_btn,
  .header_right .shoki_btn {
    margin: 15px 20px 12px;
  }
  .header_right ul li.notification span.noof_noti {
    top: 22px;
  }
  .header_right ul li.profile span.user_avtar {
    line-height: 26px;
  }
  .basket_wrap,
  .sidebar,
  .prfile_wrap {
    top: 70px;
  }
  .sidebar {
    width: 40%;
  }
  .notification_list {
    height: calc(100vh - 330px);
  }
  .noti_top {
    padding: 25px 30px 15px;
  }
  .header_btm {
    top: 70px;
  }
  .menu_main ul li {
    padding: 0 1%;
  }
  .menu_con {
    width: 90%;
  }

  .performance_nav ul li a {
    padding: 10px 15px;
  }
  .daterange .for_date {
    padding: 0 30px;
  }
  .welcome .col-sm-offset-3 {
    margin-left: 16%;
  }
  .welcome .col-sm-4 {
    width: 42%;
  }
  .top_point {
    padding: 60px 0;
  }
  .top_point .left_con {
    padding: 10px 15px 0;
  }
  .top_point .left_con::before {
    width: 160px;
  }
  .sell_point_raw {
    padding: 20px 10px;
  }
  .sell_no {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  .sell_des {
    padding: 0 10px;
  }
  .sell_type {
    width: 70%;
  }
  .selling_list .sell_type ul li {
    padding: 0 7px;
  }
  .sell_point_raw a {
    background-position: right 7px center;
    width: 20px;
  }

  .budgtes_box {
    padding: 25px;
  }
  .budeget_des span {
    width: 50%;
  }
  .facebook_budget ul li {
    width: 32%;
    margin: 35px 2% 0 0;
  }
  .spent_box {
    padding: 20px 20px 15px;
  }

  #chart #bars li {
    width: 40px;
  }
  .spent_top h3 {
    font-size: 28px;
  }
  .spent_des p {
    min-height: 70px;
  }
  #piechart div svg {
    position: relative;
    left: -25px;
    width: 290px;
  }
  g text {
    font-size: 12px !important;
  }
  #piechart::before {
    left: 0;
  }
  #piechart::after {
    left: 90px;
  }

  .ovelay_right {
    width: 50%;
  }

  #home .modal-dialog::after {
    width: 180px;
    height: 180px;
    left: -650px;
    top: -70px;
  }
  #myModal2 .modal-dialog::after {
    left: 0;
  }
  #myModal1 .tooltip_wrap {
    margin-right: 100px;
  }
  #myModal3 .tooltip_wrap {
    margin-right: 30px;
  }
  .daterangepicker {
    top: 130px !important;
  }

  .main.single_sale {
    margin-top: 135px;
  }
  .pointserch_wrap {
    top: 70px;
  }
  .sale_search {
    width: 30%;
  }
  .pointsale_slider {
    width: 64%;
  }
}

@media (max-width: 991px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 98%;
  }

  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }

  .form-control {
    height: 50px;
  }
  .registration_con {
    padding: 40px 0 0;
  }
  .form_main .btn-primary {
    padding: 0 20px;
    height: 50px;
  }
  .registration_head .btn-primary.login {
    margin: 20px 0 0;
  }
  .registration_main::before {
    width: 180px;
  }
  .registr_slider::before {
    width: 200px;
    left: -100px;
  }

  body.closed #header {
    transform: translateY(-60px);
  }
  .header_top {
    padding: 0;
    height: 60px;
  }
  .logo {
    width: 80px;
    line-height: 60px;
  }
  .header_right ul li a {
    padding: 0 15px;
    line-height: 60px;
  }
  .basket {
    width: 28px;
    line-height: 20px;
  }
  .header_right .launch_btn {
    height: 38px;
    line-height: 36px;
    padding: 0 25px;
    margin: 12px 15px 10px;
  }
  .header_right .shoki_btn {
    height: 38px;
    line-height: 36px;
    margin: 12px 15px 10px;
  }
  .header_right ul li.menu a,
  .header_right ul li.notification a {
    padding: 0 15px 0 50px;
  }
  .header_right ul li.menu a {
    background-position: left 18px center;
    background-size: 20px auto;
  }
  .header_right ul li.notification span.noof_noti {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    top: 18px;
    left: 18px;
  }
  .header_right ul li.profile span.user_avtar {
    width: 28px;
    height: 28px;
    line-height: 26px;
  }
  .basket_wrap,
  .sidebar,
  .prfile_wrap {
    top: 60px;
  }
  .sidebar {
    width: 48%;
  }
  .notification_list {
    height: calc(100vh - 320px);
  }
  .noti_top {
    padding: 25px 30px 15px;
  }
  .header_btm {
    top: 60px;
  }
  .menu_main ul li {
    padding: 0 1%;
  }
  .menu_con {
    width: 90%;
  }
  .hamburger .menu_main a {
    font-size: 14px;
  }

  .main {
    margin-top: 125px;
  }
  .main::before {
    width: 150px;
    left: -70px;
  }
  .main::after {
    width: 180px;
  }
  .performance_nav ul li a {
    padding: 8px 10px;
    font-size: 10px;
  }
  .daterange {
    margin: 0;
  }
  .daterange .for_date {
    padding: 0 15px;
  }
  .daterange label {
    font-size: 14px;
  }
  .daterange .for_date::before {
    width: 20px;
    height: 20px;
  }
  .welcome .col-sm-offset-3 {
    margin-left: 5%;
  }
  .welcome .col-sm-4 {
    width: 53%;
  }
  .graph {
    padding-top: 60px;
  }
  .top_point {
    padding: 50px 0;
  }
  .top_point .left_con {
    padding: 10px 15px 0;
  }
  .top_point .left_con::before {
    width: 160px;
  }
  .sell_point_raw {
    padding: 20px;
  }
  .sell_no {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  .sell_des {
    width: 50%;
    padding: 0 10px;
  }
  .sell_type {
    width: 100%;
    padding-top: 15px;
  }
  .selling_list .sell_type ul li {
    padding: 0 10px;
  }
  .sell_point_raw a {
    background-position: right 7px center;
    width: 20px;
  }
  .statistiques {
    padding: 0 0 50px;
  }
  .statistiques_tab .nav-tabs {
    right: 30px;
    top: -98px;
  }
  .statistiques_tab .nav-tabs li a {
    min-width: 130px;
    padding: 8px 15px;
  }
  .statistiques_tab .socialchar_tab .nav-tabs {
    top: -40px;
  }
  .nav-tabs span {
    left: 12px;
    top: 9px;
  }
  .nav-tabs li.google span {
    left: 20px;
  }
  .statistiques .title p {
    padding-top: 30px;
  }
  .statistiques_con h2 {
    font-size: 40px;
  }
  .statistiques_con .progress {
    width: 65%;
  }
  .budgtes_box {
    padding: 20px;
  }
  .budget_type span {
    padding: 0 15px 0 22px;
  }
  .budeget_des span {
    width: 60%;
    padding-right: 20px;
  }
  .facebook_budget ul li {
    width: 32%;
    margin: 25px 2% 0 0;
  }
  .spent_box {
    padding: 15px 15px 15px;
  }

  #chart #bars li {
    width: 32px;
  }
  .spent_top h3 {
    font-size: 26px;
  }
  .spent_des p {
    min-height: 90px;
  }
  #piechart div svg {
    position: relative;
    left: -25px;
    width: 290px;
  }
  g text {
    font-size: 12px !important;
  }
  #piechart::before {
    left: 0;
  }
  #piechart::after {
    left: 90px;
  }

  .campagne_main {
    padding: 50px 3% 30px;
  }
  .ovelay_left h2 {
    font-size: 28px;
  }
  .campagne_main .progress {
    width: 100px;
  }
  .campagne_main .progress_main h6 {
    width: 33%;
    padding-right: 10px;
  }
  .ovelay_right {
    width: 50%;
  }
  .campagne_main .social_data .progress {
    width: 220px;
  }
  .campagne_main .social_data .progress_main h6 {
    width: 26%;
  }
  .overlay_list {
    padding-top: 30px;
  }
  span.value {
    font-size: 22px;
  }

  .instru_model .modal-dialog {
    margin: 100px 0 0;
  }
  .tooltip_wrap {
    width: 280px;
    padding: 20px 15px 12px;
  }
  .tooltip_con {
    padding: 0 15px 0 40px;
  }
  .tooltip_con h6 {
    font-size: 16px;
  }
  #home .modal-dialog::after {
    width: 160px;
    height: 160px;
    left: -500px;
    top: -70px;
  }
  #myModal2 .modal-dialog::after {
    left: 0;
  }
  #myModal1 .tooltip_wrap {
    margin-right: 30px;
  }
  #myModal2 .tooltip_wrap {
    margin-right: 280px;
  }
  #myModal3 .tooltip_wrap {
    width: 350px;
    margin-right: 0;
  }
  #myModal3 .modal-dialog::after {
    top: 20px;
    left: -550px;
  }
  .instru_model #myModal3 .modal-dialog {
    margin: 480px 0 0;
  }
  .daterangepicker {
    width: 490px !important;
    left: 35% !important;
    top: 115px !important;
  }

  .main.single_sale {
    margin-top: 125px;
  }
  .pointserch_wrap {
    top: 60px;
  }
  .sale_search {
    width: 35%;
  }
  .sale_search .serch_txt {
    font-size: 12px;
  }
  .pointsale_slider {
    width: 56%;
  }
}
@media only screen and (min-width: 850px) and (max-width: 1024px) {
}
@media only screen and (min-width: 900px) and (max-width: 1024px) {
}
@media only screen and (min-width: 950px) and (max-width: 1024px) {
}

@media (max-width: 767px) {
  .nav_main ul li a::before {
    display: none;
  }
  .registr_slider {
    position: relative;
    padding: 0 15px;
    left: inherit;
    right: inherit;
    display: none;
  }
  .registr_slider .mySlides::before {
    min-height: 200px;
  }

  .main.single_sale {
    margin-top: 100px;
  }
  .pointserch_wrap {
    height: auto;
    position: relative;
    top: 0;
    padding: 0 6%;
  }
  .sale_search {
    width: 100%;
  }
  .sale_search .serch_txt {
    font-size: 12px;
  }
  .pointsale_slider {
    margin-top: 20px;
    width: 100%;
  }

  #home .modal-dialog:after {
    box-shadow: 0 0 0 945px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 0 945px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 0 945px rgba(0, 0, 0, 0.5);
  }

  .reset-main .logo {
    width: 140px;
  }
  .reset-main h1 {
    font-size: 22px;
  }
  .reset-main h1 br {
    display: none;
  }

  .notrecvie-raw {
    padding: 0;
  }
  .notrecvie-raw label {
    width: 100%;
    margin: 0;
    padding: 0 0 10px 0;
    position: relative;
    left: 0;
    text-align: left;
    top: 0;
  }
  .reset-main .form-main .form-control {
    height: 50px;
  }
}

@media only screen and (max-device-width: 480px) {
  html {
    -webkit-text-size-adjust: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 96%;
  }

  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  .btn-primary {
    font-size: 12px;
    height: 40px;
    line-height: 38px;
  }
  .form-control {
    height: 50px;
  }
  .registration_main {
    padding: 30px 40px 60px;
  }
  .registration_head {
    float: left;
    width: 100%;
  }
  .registration_head span {
    font-size: 14px;
  }
  .registration_head .btn-primary.login {
    margin: -10px 0 0 15px;
  }
  .form_main .btn-primary {
    height: 50px;
    font-size: 14px;
  }
  .registration_con {
    padding: 30px 0 0;
  }
  .registration_main::before {
    width: 150px;
    left: -25px;
  }
  .registr_slider::before {
    width: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -210px;
  }
  .registr_slider h2 {
    bottom: 100px;
  }
  .indicators {
    bottom: 60px;
  }

  body.closed #header {
    transform: translateY(-51px);
  }
  .header_top {
    padding: 0;
    height: auto;
  }
  .header_top:before {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 1px;
    background: #dbdbdb;
    content: '';
  }
  .logo {
    width: 110px;
    line-height: 50px;
  }
  .header_right {
    position: relative;
    float: left;
    width: 100%;
    left: 0;
    right: 0;
  }
  .header_right ul {
    width: 100%;
  }
  .header_right ul li {
    width: 12%;
    text-align: center;
  }
  .header_right ul li:first-child {
    border: none;
  }
  .header_right ul li:nth-child(2) {
    width: 52%;
  }
  .header_right ul li a {
    font-size: 0;
    padding: 0 5px;
    line-height: 50px;
    width: 100%;
  }
  .basket {
    width: 28px;
    line-height: 20px;
  }
  .basket_wrap {
    width: 260px;
    padding: 20px 15px;
  }
  ul.cart_item .imgwrap {
    height: 68px;
  }
  .item_des {
    padding: 0 0 0 20px;
  }
  .quantity_count {
    width: 60%;
    padding: 6px;
  }
  .quantity_count .qty {
    width: 25px;
  }
  .basket_wrap .btn-primary {
    height: 44px;
    line-height: 42px;
  }
  .header_right .launch_btn,
  .header_right .shoki_btn {
    height: 34px;
    line-height: 33px;
    padding: 0 25px;
    margin: 8px 6%;
    width: 87%;
  }
  .header_right ul li.menu a,
  .header_right ul li.notification a {
    padding: 0 15px;
  }
  .header_right ul li.menu a {
    background-position: center;
    background-size: 20px;
  }
  .header_right ul li.notification span.noof_noti {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    top: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .header_right ul li.profile span.user_avtar {
    width: 28px;
    height: 28px;
    line-height: 26px;
  }
  .basket_wrap,
  .prfile_wrap {
    top: 52px;
  }
  .sidebar {
    width: 80%;
    top: 100px;
  }
  .sidebar .notification_main .activity_btn {
    height: 40px;
    line-height: 46px;
  }
  .notification_list {
    height: calc(100vh - 348px);
  }
  body.closed .notification_list {
    height: calc(100vh - 298px);
  }
  .noti_top {
    padding: 25px 30px 15px;
  }
  .prfile_wrap {
    width: 190px;
    padding: 5px 12px 15px;
    right: inherit;
  }
  .header_right .prfile_wrap ul li a {
    background-size: 14px auto !important;
    padding: 5px 0 5px 20px;
    text-align: left;
    font-size: 12px;
  }
  .header_right .prfile_wrap ul li a:hover {
    padding: 5px 0 5px 25px;
  }
  .header_btm {
    top: 0 !important;
    position: relative;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .hamburger.active {
    overflow: auto;
  }
  .menu_main ul li {
    width: 50%;
    padding: 0 1%;
  }
  .menu_con {
    width: 90%;
    top: 50%;
  }
  .hamburger .menu_main a {
    font-size: 14px;
  }

  .main {
    margin-top: 85px;
  }
  .main::before {
    width: 100px;
    left: -50px;
    top: 120px;
  }
  .main::after {
    width: 150px;
    top: 450px;
    right: 15px;
  }
  .performance_nav {
    flex-direction: column;
  }
  .performance_nav ul li {
    width: 33%;
  }
  .performance_nav ul li a {
    padding: 8px 5px;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
  .performance_nav ul li:nth-child(3n) a {
    border-right: 1px solid #ccc;
    border-radius: 0 3px 3px 0;
  }
  .performance_nav ul li:first-child a {
    border-radius: 3px 0 0 0;
  }
  .performance_nav ul li:last-child a {
    border-radius: 0 0 3px 0;
  }
  .performance_nav ul li:nth-last-child(-n + 2) a {
    border-top: none;
  }
  .daterange {
    float: right;
  }
  .daterange .for_date {
    padding: 0 25px;
  }
  .daterange label {
    font-size: 14px;
  }
  .daterange .for_date::before {
    width: 20px;
    height: 20px;
  }
  .daterange .for_date:last-child {
    padding-right: 0;
  }
  .welcome .left_con {
    padding-top: 0;
  }
  .welcome .col-sm-4 {
    padding-top: 40px;
  }
  .graph {
    padding-top: 60px;
  }
  .top_point {
    padding: 50px 0;
  }
  .top_point .left_con {
    padding: 10px 15px 0;
  }
  .top_point .left_con::before {
    width: 160px;
    right: 0;
  }
  .sell_point_raw {
    padding: 20px;
  }
  .sell_no {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  .sell_des {
    width: 70%;
    padding: 0 10px;
  }
  .sell_type {
    width: 100%;
    padding-top: 0;
  }
  .selling_list .sell_type ul li {
    width: 33%;
    padding: 15px 10px 0 0;
    text-align: left;
  }
  .sell_point_raw a {
    background-position: right 7px center;
    width: 20px;
  }
  .statistiques {
    padding: 0 0 50px;
    overflow: hidden;
  }
  .statistiques_tab .nav-tabs {
    float: right;
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .statistiques_tab .nav-tabs li a {
    min-width: 130px;
    padding: 8px 15px;
  }
  .socialchar_tab {
    margin-top: 20px;
  }
  .statistiques_tab .socialchar_tab .nav-tabs {
    top: 0;
  }
  .nav-tabs span {
    left: 12px;
    top: 9px;
  }
  .nav-tabs li.google span {
    left: 20px;
  }
  .statistiques .title p {
    padding-top: 30px;
  }
  .statistiques_con h2 {
    font-size: 34px;
  }
  .statistiques_con .progress {
    width: 90%;
  }
  #regions > div {
    position: relative;
    right: 25px;
  }

  .budgtes_box {
    padding: 20px 15px;
    margin-top: 25px;
    min-height: 206px;
  }
  .budget_type span {
    padding: 1px 12px 1px 22px;
    font-size: 12px;
  }
  .budeget_des span {
    width: 65%;
    padding-right: 20px;
  }
  .facebook_budget ul li {
    width: 49%;
    margin: 25px 2% 0 0;
  }
  .facebook_budget ul li:nth-child(2n) {
    margin-right: 0 !important;
  }
  .facebook_budget ul li:nth-child(3n) {
    margin-right: 2%;
  }
  .spent_box {
    padding: 15px 15px 15px;
  }

  #chart #bars li {
    width: 32px;
  }
  .spent_top h3 {
    font-size: 26px;
  }
  .spent_des p {
    min-height: 90px;
  }
  #piechart div svg {
    position: relative;
    left: -25px;
    width: 290px;
  }
  g text {
    font-size: 12px !important;
  }
  #piechart::before {
    left: 0;
  }
  #piechart::after {
    left: 90px;
  }

  .campagne_main {
    padding: 30px 25px;
  }
  .ovelay_main {
    height: 200px;
    overflow: scroll;
  }
  .ovelay_left {
    width: 100%;
  }
  .ovelay_left h2 {
    font-size: 26px;
  }
  .campagne_main .progress {
    width: 100px;
  }
  .campagne_main .progress_main h6 {
    width: 25%;
    padding-right: 10px;
  }
  .ovelay_right {
    width: 100%;
    text-align: left;
    padding-top: 20px;
  }
  .campagne_main .social_data .progress {
    width: 220px;
  }
  .campagne_main .social_data .progress_main h6 {
    width: 25%;
    text-align: left;
  }
  .overlay_list {
    padding-top: 20px;
  }
  .overlay_list span {
    min-height: 23px;
  }
  .overlay_list ul li {
    width: 33%;
    padding: 20px 12px 0 0;
  }

  span.value {
    font-size: 20px;
  }
  .overlay_list h6 {
    padding-top: 15px;
    font-size: 12px;
  }

  .instru_model .modal-dialog {
    margin: 100px 0 0;
  }
  .tooltip_wrap {
    width: 280px;
    padding: 20px 15px 12px;
  }
  .tooltip_con {
    padding: 0 15px 0 30px;
  }
  .tooltip_con .ico {
    width: 20px;
    height: 20px;
  }
  .tooltip_con h6 {
    font-size: 16px;
  }
  .tooltip_con ul li {
    padding: 3px 0 5px 35px;
  }
  #home .modal-dialog::after {
    height: 120px;
    left: -340px;
    top: -40px;
    width: 120px;
  }
  #myModal2 .modal-dialog::after {
    left: 0;
    right: -58%;
  }
  #myModal1 .tooltip_wrap {
    margin-right: -20%;
  }
  #myModal2 .tooltip_wrap {
    margin-right: 280px;
  }
  #myModal3 .tooltip_wrap {
    width: 350px;
    margin-right: 0;
  }
  #myModal3 .modal-dialog::after {
    top: 20px;
    left: -550px;
  }
  .instru_model #myModal3 .modal-dialog {
    margin: 800px 0 0;
  }
  .daterangepicker {
    left: inherit !important;
    right: 15px !important;
    top: 230px !important;
    width: 402px !important;
  }
  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    width: 26px;
    min-width: 26px;
    height: 18px;
    line-height: 18px;
  }
  .daterangepicker .drp-selected {
    font-size: 14px;
  }
  .daterangepicker.show-calendar .ranges {
    margin: 0;
  }
  .drp-buttons {
    margin-top: 10px;
  }
  body.closed .daterangepicker {
    top: 42px !important;
  }
  .copy_right {
    float: left;
  }

  .reset-main {
    width: 80%;
    padding: 30px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  #regions > div {
    right: 5px;
  }
}
@media only screen and (min-width: 650px) and (max-width: 767px) {
  #regions > div {
    right: 0;
  }
  #home .modal-dialog:after {
    box-shadow: 0 0 0 950px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 0 950px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 0 950px rgba(0, 0, 0, 0.5);
  }
}
@media only screen and (min-width: 700px) and (max-width: 767px) {
}

@media (max-width: 479px) {
  .container {
    width: 100%;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  .btn-primary {
    font-size: 12px;
    height: 40px;
    line-height: 38px;
  }
  .form-control {
    height: 50px;
  }
  .registration_main {
    padding: 30px 40px 60px;
  }
  .registration_head {
    float: left;
    width: 100%;
  }
  .registration_head span {
    width: 100%;
    font-size: 14px;
  }
  .registration_head .btn-primary.login {
    float: left;
    margin: 15px 0 0;
  }
  .form_main .btn-primary {
    height: 50px;
    font-size: 14px;
  }
  .registration_con {
    padding: 30px 0 0;
  }
  .registration_main::before {
    width: 130px;
    left: -25px;
  }
  .registr_slider::before {
    width: 150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -210px;
  }
  .registr_slider h2 {
    bottom: 100px;
  }
  .indicators {
    bottom: 60px;
  }

  body.closed #header {
    transform: translateY(-51px);
  }
  .header_top {
    padding: 0;
    height: auto;
  }
  .header_top:before {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 1px;
    background: #dbdbdb;
    content: '';
  }
  .logo {
    width: 100px;
    line-height: 50px;
  }
  .header_right {
    position: relative;
    float: left;
    width: 100%;
    left: 0;
    right: 0;
  }
  .header_right ul {
    width: 100%;
  }
  .header_right ul li {
    width: 25%;
    text-align: center;
  }
  .header_right ul li:first-child {
    border: none;
  }
  .header_right ul li:nth-child(2) {
    float: right;
  }
  .header_right ul li a {
    font-size: 0;
    padding: 0 5px;
    line-height: 50px;
    width: 100%;
  }
  .basket {
    width: 28px;
    line-height: 20px;
  }
  .basket_wrap {
    width: 260px;
    padding: 20px 15px;
  }
  ul.cart_item .imgwrap {
    height: 68px;
  }
  .item_des {
    padding: 0 0 0 20px;
  }
  .quantity_count {
    width: 60%;
    padding: 6px;
  }
  .quantity_count .qty {
    width: 25px;
  }
  .basket_wrap .btn-primary {
    height: 44px;
    line-height: 42px;
  }
  .header_right .launch_btn,
  .header_right .shoki_btn {
    width: 180px;
    height: 34px;
    line-height: 33px;
    padding: 0 20px;
    margin: 0;
    position: absolute;
    right: 0;
    top: -42px;
  }
  .header_right ul li.menu a,
  .header_right ul li.notification a {
    padding: 0 15px;
  }
  .header_right ul li.menu a {
    background-position: center;
    background-size: 24px;
  }
  .header_right ul li.notification span.noof_noti {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    top: 8px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .header_right ul li.profile span.user_avtar {
    width: 35px;
    height: 35px;
    line-height: 26px;
  }
  .basket_wrap,
  .prfile_wrap {
    top: 51px;
  }
  .sidebar {
    width: 100%;
    top: 100px;
  }
  .sidebar .notification_main .activity_btn {
    height: 40px;
    line-height: 46px;
  }
  .notification_list {
    height: calc(100vh - 348px);
  }
  body.closed .notification_list {
    height: calc(100vh - 298px);
  }
  .noti_top {
    padding: 20px 15px 15px;
  }
  .prfile_wrap {
    width: 190px;
    padding: 5px 12px 15px;
  }
  .header_right .prfile_wrap ul li a {
    background-size: 14px auto !important;
    padding: 5px 0 5px 20px;
    text-align: left;
    font-size: 12px;
  }
  .header_right .prfile_wrap ul li a:hover {
    padding: 5px 0 5px 25px;
  }
  .header_btm {
    top: 0;
    position: relative;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .hamburger.active {
    overflow: auto;
  }
  .menu_main ul li {
    width: 100%;
    padding: 0;
  }
  .menu_con {
    width: 90%;
    top: 50%;
  }
  .hamburger .menu_main a {
    font-size: 14px;
  }

  .main {
    margin-top: 85px;
  }
  .main::before {
    width: 100px;
    left: -50px;
    top: 170px;
  }
  .main::after {
    width: 150px;
    top: 450px;
    right: 15px;
  }
  .performance_nav {
    flex-direction: column;
  }
  .performance_nav ul li {
    width: 50%;
  }
  .performance_nav ul li a {
    padding: 8px 5px;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
  .performance_nav ul li:nth-child(2n) a {
    border-right: 1px solid #ccc;
    border-radius: 0 0 3px 0;
  }
  .performance_nav ul li:nth-child(2) a {
    border-radius: 0 3px 0 0;
  }
  .performance_nav ul li:first-child a {
    border-radius: 3px 0 0 0;
  }
  .performance_nav ul li:last-child a {
    border-radius: 0 0 3px 0;
    border-top: none;
  }
  .performance_nav ul li:nth-child(-n + 2) a {
    border-bottom: none;
  }

  .daterange {
    float: right;
    margin: 15px 0 0;
  }
  .daterange .for_date {
    padding: 0 25px;
  }
  .daterange label {
    font-size: 14px;
  }
  .daterange .for_date::before {
    width: 20px;
    height: 20px;
  }
  .daterange .for_date:last-child {
    padding-right: 0;
  }
  .welcome .left_con {
    padding-top: 0;
  }
  .welcome .col-sm-4 {
    padding-top: 40px;
  }
  .activity_tab {
    padding: 15px 5%;
  }
  .activity_btn {
    height: auto;
    padding: 10px;
    line-height: inherit;
  }
  .activity_btn span {
    padding-left: 30px;
  }
  .graph {
    padding-top: 60px;
  }
  .top_point {
    padding: 50px 0;
  }
  .top_point .left_con {
    padding: 10px 15px 0;
  }
  .top_point .left_con::before {
    width: 160px;
    right: 0;
  }
  .sell_point_raw {
    padding: 20px 20px 20px 10px;
  }
  .sell_no {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }
  .sell_des {
    width: 88%;
    padding: 0 10px;
  }
  .sell_type {
    width: 100%;
    padding-top: 0;
  }
  .selling_list .sell_type ul li {
    width: 33%;
    padding: 15px 10px 0 0;
    text-align: left;
  }
  .sell_point_raw a {
    background-position: right 7px center;
    width: 20px;
  }
  .statistiques {
    padding: 0 0 40px;
    overflow: hidden;
  }
  .statistiques_tab .nav-tabs {
    float: right;
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 20px;
  }
  .statistiques_tab .nav-tabs li a {
    min-width: 130px;
    padding: 8px 15px;
  }
  .socialchar_tab {
    margin-top: 20px;
  }
  .statistiques_tab .socialchar_tab .nav-tabs {
    top: 0;
  }
  .nav-tabs span {
    left: 12px;
    top: 9px;
  }
  .nav-tabs li.google span {
    left: 20px;
  }
  .statistiques .title p {
    padding-top: 30px;
  }
  .statistiques_con h2 {
    font-size: 30px;
  }
  .statistiques_con .progress {
    width: 95%;
  }
  #regions > div {
    position: relative;
    right: 15px;
  }

  .google_budgtes .col-sm-4 {
    width: 100%;
  }
  .budgtes_box {
    padding: 20px 15px;
    margin-top: 25px;
    min-height: 206px;
  }
  .budget_type span {
    padding: 1px 12px 1px 22px;
    font-size: 12px;
  }
  .budeget_des span {
    width: 42%;
    padding-right: 20px;
  }
  .facebook_budget ul li {
    width: 100%;
    margin: 25px 0 0;
  }
  .facebook_budget ul li:nth-child(2n) {
    margin-right: 0 !important;
  }
  .facebook_budget ul li:nth-child(3n) {
    margin-right: 0;
  }
  .spent_box {
    padding: 15px 15px 15px;
  }

  #chart #bars li {
    width: 42px;
  }
  .spent_top h3 {
    font-size: 26px;
  }

  #piechart div svg {
    position: relative;
    left: -25px;
    width: 290px;
  }
  g text {
    font-size: 12px !important;
  }
  #piechart::before {
    left: 0;
  }
  #piechart::after {
    left: 90px;
  }

  .campagne_main {
    padding: 30px 5%;
  }
  .ovelay_main {
    height: 200px;
    overflow: scroll;
  }
  .ovelay_left {
    width: 100%;
  }
  .ovelay_left h2 {
    font-size: 24px;
  }
  .campagne_main .progress {
    width: 80px;
  }
  .campagne_main .progress_main h6 {
    width: 35%;
    padding-right: 10px;
  }
  .ovelay_right {
    width: 100%;
    text-align: left;
    padding-top: 20px;
  }
  .campagne_main .social_data .progress {
    width: 50%;
  }
  .campagne_main .social_data .progress_main h6 {
    width: 35%;
    text-align: left;
  }
  .overlay_list {
    padding-top: 20px;
  }
  .overlay_list span {
    min-height: 23px;
  }
  .overlay_list ul li {
    width: 33%;
    padding: 20px 12px 0 0;
  }

  span.value {
    font-size: 18px;
  }
  .overlay_list h6 {
    padding-top: 15px;
    font-size: 12px;
  }

  .instru_model .modal-dialog {
    margin: 190px 0 0;
  }
  .tooltip_wrap {
    width: 90%;
    padding: 20px 15px 12px;
  }
  .tooltip_con {
    padding: 0 15px 0 30px;
  }
  .tooltip_con .ico {
    width: 20px;
    height: 20px;
  }
  .tooltip_con h6 {
    font-size: 16px;
  }
  .tooltip_con ul li {
    padding: 3px 0 5px 35px;
  }
  #home .modal-dialog::after {
    height: 120px;
    left: inherit;
    right: 30px;
    top: -140px;
    width: 120px;
  }
  #myModal2 .modal-dialog {
    margin: 290px 0 0;
  }

  #myModal2 .tooltip_wrap::before {
    left: 70px !important;
    transform: rotate(-90deg);
  }
  #myModal2 .modal-dialog::after {
    left: 0;
    right: 50%;
    top: -140px;
  }
  #myModal1 .tooltip_wrap {
    margin-right: 0;
  }
  #myModal2 .tooltip_wrap {
    margin-right: 0;
  }
  #myModal3 .tooltip_wrap {
    width: 350px;
    margin-right: 0;
  }
  #myModal3 .modal-dialog::after {
    top: 20px;
    left: -550px;
  }
  .tooltip_wrap::before {
    transform: rotate(90deg);
    top: -15px;
    left: inherit;
    right: 70px;
  }
  .instru_model #myModal3 .modal-dialog {
    margin: 800px 0 0;
  }
  .daterangepicker {
    left: inherit !important;
    right: 0 !important;
    top: 265px !important;
    width: 320px !important;
  }
  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    font-size: 12px;
    height: 16px;
    line-height: 15px;
    min-width: 20px;
    padding: 3px;
    width: 20px;
  }
  .daterangepicker .drp-selected {
    float: left;
    font-size: 14px;
    padding-bottom: 15px;
    width: 100%;
  }
  .daterangepicker.show-calendar .ranges {
    margin: 0;
  }
  .drp-buttons {
    margin-top: 10px;
  }
  body.closed .daterangepicker {
    top: 42px !important;
  }
  .copy_right {
    float: left;
  }

  .reset-main {
    width: 100%;
    padding: 30px 15px;
  }
  .passport-con h1 {
    font-size: 22px;
  }
  .notrecvie-main.form-main .btn-main {
    font-size: 12px;
    padding: 0;
  }
}

@media only screen and (min-width: 350px) and (max-width: 479px) {
  .activity_l {
    width: 40%;
    padding-right: 12px;
  }
  .sell_point_raw {
    padding: 20px 20px 20px 15px;
  }
  .daterangepicker {
    right: 15px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 479px) {
  .activity_l {
    width: 48%;
    padding-right: 12px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 479px) {
}
@media only screen and (min-width: 450px) and (max-width: 479px) {
}

@media (max-width: 299px) {
}
