/** 
 * ---------------------
 * Remove input autofill 
 * ---------------------
 **/

/* Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Firefox */
input {
  filter: none;
}

/* ---------------------- */

* {
  box-sizing: inherit;
  outline: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}
html {
  overflow-x: hidden;
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}
html.Home,
.Home body {
  overflow: hidden;
}
html,
html a,
body {
  -webkit-font-smoothing: antialiased;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
ol,
ul {
  list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}
button {
  cursor: pointer;
  border: none;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
blockquote,
q {
  quotes: '' '';
}
body {
  font-size: 16px;
  background: #f6f8fa;
  font-size: 100%;
  color: #000;
  line-height: 100%;
  font-style: normal;
  font-weight: normal;
}
input[type='checkbox'] {
  vertical-align: top;
}
input[type="radio"] {
  /*height: auto;*/
  vertical-align: top;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
table {
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: top;
}
img {
  border: 0 none;
  max-width: 100%;
}
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
button[disabled],
button[disabled]:hover,
.btn-primary[disabled],
.btn-primary[disabled]:hover {
  background: #a4a4a4;
}
.left {
  float: left;
}
.right {
  float: right;
}
.clear {
  clear: both;
}
.text_right {
  text-align: right;
}
.text_left {
  text-align: left;
}
.text_center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.clearfix {
  clear: both;
  /*content:" "; never use this property error in opera*/
  display: block;
  font-size: inherit;
  height: 0;
  line-height: 0;
}
p {
  float: left;
  width: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
a:focus,
a:hover {
  text-decoration: none;
}
.purple {
  color: #8e54e9 !important;
}
.orange {
  color: #f2994a !important;
}
.red {
  color: #eb5757 !important;
}
.green {
  color: #13d60f !important;
}
.bg_white {
  background: #ffffff;
}
label {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  color: #273138;
  position: relative;
}
/*-------------------Index Page Start-----------------------------*/
.header {
  width: 100%;
}
#header {
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 999;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
/*#header.affix{
	background:#FFF;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}*/
.Home body.closed #header {
  transform: none;
}
.Home body.closed .header_btm {
  top: 80px;
}

body.closed #header {
  transform: translateY(-80px);
  -webkit-transform: translateY(-80px);
  -moz-transform: translateY(-80px);
}
.header_main {
  width: 100%;
}
.header_top {
  height: 80px;
  border-bottom: 1px solid #dbdbdb;
  padding: 0 2%;
  position: relative;
}
.logo {
  width: 140px;
  height: auto;
  line-height: 80px;
}
.logo a {
  width: 100%;
  height: auto;
}
.logo a img {
  display: inline-block;
  width: auto;
  height: auto;
  transition: all 0.4s ease-out 0s;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
}
.header_right {
  float: right;
  width: auto;
  position: absolute;
  right: 2%;
  top: 0;
}
.header_right ul {
  width: auto;
}
.header_right ul li {
  width: auto;
  height: 100%;
  position: relative;
}
.header_right ul li a,
.header_right ul li span.username {
  width: auto;
  height: 100%;
  line-height: 80px;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  position: relative;
}
.header_right ul li span.username {
  color: #93a1ad;
}
.header_right ul li.menu a {
  background: url(../images/ic-menu.svg) no-repeat left 30px center;
}
.header_right ul li.menu a,
.header_right ul li.notification a {
  padding: 0 30px 0 70px;
}
.header_right ul li.notification span.noof_noti {
  background: #f5eefe;
  width: 30px;
  height: 30px;
  line-height: 28px;
  position: absolute;
  left: 30px;
  top: 26px;
  font-size: 13px;
  font-weight: 700;
  color: #8e54e9;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  text-align: center;
}
.header_right ul li a img {
  display: inline-block;
  position: relative;
}
.header_right ul li.profile span.user_avtar {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.basket {
  display: inline-block;
  width: 35px;
  height: auto;
  line-height: 30px;
  vertical-align: middle;
  position: relative;
}
.basket img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.basket.in:before {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  content: '';
  z-index: 9;
}
.noof_item {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  width: auto;
}

.header_right .launch_btn {
  height: 42px;
  line-height: 40px;
  margin: 22px 30px 15px;
  padding: 0 50px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  text-shadow: none;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  letter-spacing: 0;
}
.header_right .shoki_btn {
  height: 42px;
  line-height: 40px;
  margin: 22px 30px 15px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  position: relative;
  letter-spacing: 0;
}

@mixin fade-transition($element) {
  -webkit-transition: $element 0.15s ease-in-out;
  -moz-transition: $element 0.15s ease-in-out;
  -ms-transition: $element 0.15s ease-in-out;
  -o-transition: $element 0.15s ease-in-out;
  transition: $element 0.15s ease-in-out;
}
i.effect {
  /*   positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /*   gradient   */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(1%, rgba(255, 255, 255, 0.03)),
    color-stop(30%, rgba(255, 255, 255, 0.85)),
    color-stop(50%, rgba(255, 255, 255, 0.85)),
    color-stop(70%, rgba(255, 255, 255, 0.85)),
    color-stop(71%, rgba(255, 255, 255, 0.85)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

  /*  forming the shine element
    play around with the width, skew and gradient to get different effects
  */
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  -moz-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);

  /*  animating it  */
  animation: move 2s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-transform: skew(-10deg, 0deg);
  -moz-animation: move 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-transform: skew(-10deg, 0deg);
  -ms-animation: move 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-transform: skew(-10deg, 0deg);
  -o-animation: move 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
}

/*  */
@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 88%;
  }
}

@-ms-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
  100% {
    left: 82%;
  }
}

.closed .header_btm,
.closed .sms_head,
.closed .pointserch_wrap,
.closed .head_search {
  top: 0;
}
.header_btm {
  background: #fff;
  padding: 15px 0;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  position: fixed;
  z-index: 9;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  top: 80px;
}
.performance_nav {
  display: flex;
  width: 100%;
}
.performance_nav ul li {
  float: left;
  width: auto;
  position: relative;
}
.performance_nav ul li a {
  float: left;
  width: auto;
  padding: 10px 30px;
  font-size: 12px;
  font-weight: 500;
  color: #6e6e6e;
  line-height: 120%;
  border: 1px solid #ccc;
  border-right: none;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.performance_nav ul li a:before,
.activity_tab ul li a:before,
.nav-tabs li a:before {
  background: rgba(0, 0, 0, 0);
  width: 1px;
  height: 100%;
  content: '';
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 9;
}
.performance_nav ul li:first-child a {
  border-radius: 3px 0 0 3px;
}
.performance_nav ul li:last-child a {
  border-radius: 0 3px 3px 0;
  border-right: 1px solid #ccc;
}
.performance_nav ul li:last-child a:before {
  display: none;
}
.performance_nav ul li a:hover,
.performance_nav ul li.active a {
  background: rgba(19, 214, 15, 0.12);
  border-color: #13d60f;
  color: #13d60f;
}
.performance_nav ul li:hover a:before,
.performance_nav ul li.active a:before {
  background: #13d60f !important;
}

.tooltip_wrap {
  background: #ffffff;
  display: inline-block;
  width: 320px;
  padding: 32px 20px 12px;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  z-index: 99;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.tooltip_wrap:before {
  position: absolute;
  left: -9px;
  top: 15px;
  content: '';
  width: 0;
  height: 0;
  border-width: 10px 10px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  border-style: solid;
}
#home .modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  float: left;
  background: none !important;
  opacity: 1 !important;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
}
#home .modal-dialog:after {
  content: ' ';
  position: absolute;
  top: -50px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 0 1500px rgba(0, 0, 0, 0.5);
  behavior: url(PIE.htc);
}
.tooltip_con {
  float: left;
  padding: 0 25px 0 50px;
  position: relative;
  text-align: left;
}
.tooltip_con h6 {
  font-size: 18px;
}
.tooltip_con p {
  padding-top: 5px;
  font-size: 14px;
}
.tooltip_con ul li {
  float: left;
  width: 100%;
  margin-top: 12px;
  padding: 3px 0 5px 45px;
  font-size: 14px;
  color: #6e6e6e;
  line-height: 120%;
  position: relative;
}
.tooltip_con ul li .ico {
  width: 22px;
  height: 22px;
  top: 2px;
}
.tooltip_con .ico {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 5px;
}
.tooltip_con .ico img {
  display: inline-block;
}
.tip_action {
  float: right;
  width: auto;
  margin-top: 20px;
}
.tip_action a {
  background: transparent;
  float: left;
  width: auto;
  padding: 0;
  margin-left: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: #b5b5b5;
  text-decoration: none;
  text-transform: uppercase;
  border: none !important;
}
.performance_nav .tip_action a:hover {
  background: transparent;
}
#myModal2 .tooltip_wrap:before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #ffffff;
  left: 100% !important;
}
#myModal1 .modal-dialog:after {
  left: -800px;
}
#myModal2 .modal-dialog:after {
  top: -90px;
  left: 0;
  right: -190px;
}
#myModal2 .tooltip_wrap {
  margin-right: 370px;
}
#myModal3 .modal-dialog:after {
  left: -800px;
}
.daterange {
  float: left;
  width: auto;
  margin: 2px 0 0 5px;
  cursor: pointer;
}
.daterange h6 {
  float: none;
  width: auto;
  font-size: 12px;
  text-transform: uppercase;
}
.daterange .for_date {
  float: left;
  width: auto;
  padding: 0 35px;
  position: relative;
}
.daterange .for_date:last-child:before {
  display: none;
}
.daterange label {
  font-size: 16px;
  font-weight: 500;
  color: #13d60f;
  line-height: 100%;
  cursor: pointer;
}

section {
  background: #f6f8fa none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
}
/*-----------------res-nav-Start-------------------*/
.res_navigation {
  display: none;
  width: 72px;
  height: 22px;
  position: absolute;
  right: 16px;
  top: 28px;
}
.nav-toggle {
  position: absolute;
  top: 0;
  right: 22px;
  z-index: 999999;
  padding: 5px 35px 13px 0;
  cursor: pointer;
}
.nav-toggle:focus {
  outline: none;
}
.nav-toggle.active .top {
  -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
  -ms-transform: translateY(12px) translateX(0) rotate(45deg);
  transform: translateY(12px) translateX(0) rotate(45deg);
  background: #ffffff;
}
.nav-toggle.active .middle {
  opacity: 0;
  background: #fff;
}
.nav-toggle.active .bottom {
  -webkit-transform: translateY(-7px) translateX(0) rotate(-45deg);
  -ms-transform: translateY(-7px) translateX(0) rotate(-45deg);
  transform: translateY(-7px) translateX(0) rotate(-45deg);
  background: #ffffff;
}
.nav-toggle span {
  background: #ffffff;
  border: none;
  height: 2px;
  width: 100%;
  position: absolute;
  top: -2px;
  left: 0;
  -webkit-transition: all 0.55s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.nav-toggle span:nth-of-type(2) {
  top: 7px;
}
.nav-toggle span:nth-of-type(3) {
  top: 16px;
}
#header.affix .nav-toggle span {
  background: #23a9f6;
}
#header.affix .nav-toggle.active span {
  background: #fff;
}
/*-----------------res-nav-end-------------------*/
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp,
.wow,
.zoomIn {
  visibility: hidden;
}
.btn-primary,
.btn-secondary {
  float: left;
  width: auto;
  height: 42px;
  line-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-shadow: none;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.btn-primary:hover,
.btn-primary:focus,
.hero_con .btn-primary:hover,
.btn-primary.active,
.btn-primary:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover {
  text-decoration: none;
  position: relative;
  border-color: transparent;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}
.btn-outline-primary {
  background: transparent;
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: none;
  font-weight: 500;
  text-transform: inherit;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.form-control {
  background: #ffffff;
  border: 1px solid transparent;
  float: left;
  width: 100%;
  height: 56px;
  padding: 0 15px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  color: #93a1ad;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  behavior: url(PIE.htc);
  position: relative;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
/*------------index page start---------------*/
.section,
ul {
  float: left;
  width: 100%;
}
.lead_section {
  padding-bottom: 50px;
}
.container {
  width: 1240px;
}
.main {
  padding-top: 15px;
  position: relative;
}
.main:before,
.main:after {
  position: absolute;
  content: '';
  background-size: 100% !important;
  z-index: -9;
}
.main:before {
  background: url(../images/left-sep.svg) no-repeat center center;
  left: -130px;
  top: 40px;
  width: 205px;
  height: 160px;
}
.main:after {
  background: url(../images/right-sep.svg) no-repeat center center;
  right: 20px;
  top: 135px;
  width: 200px;
  height: 180px;
}

.welcome {
  position: relative;
}
.welcome .left_con {
  padding-top: 50px;
}
.welcome .left_con p {
  padding-top: 10px;
}

.slaes_activity {
  background: #fff;
  float: left;
  width: 100%;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  behavior: url(PIE.htc);
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.activity_tab {
  float: left;
  width: 100%;
  padding: 15px 25px 20px;
}
.activity_tab ul li {
  float: left;
  width: 33.33%;
}
.nav-tabs li {
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 0;
  margin: 0;
}
.nav-tabs li a {
  float: left;
  width: 100%;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  color: #9b9b9b;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  border: none !important;
  border-radius: 0 !important;
}
.nav-tabs {
  border: none;
  min-height: 28px;
}
.tab-content {
  float: left;
  width: 100%;
  position: relative;
}
.nav-tabs li:first-child {
  border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
}
.nav-tabs li:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #ccc;
}
.nav-tabs li:last-child a:before {
  display: none;
}
.nav-tabs ul li:hover a,
.nav-tabs li:hover,
.nav-tabs li.active,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  background: rgba(19, 214, 15, 0.12);
  border-color: #13d60f !important;
  color: #13d60f;
}
.nav-tabs li.active {
  cursor: default;
}
.nav-tabs li:hover a:before,
.nav-tabs li.active a:before {
  background: #13d60f !important;
}
.activity_list {
  float: left;
  width: 100%;
  padding-top: 5px;
}
.activity_tab .activity_list ul li {
  float: left;
  width: 100%;
  padding-top: 20px;
  border: none;
}
.activity_l {
  float: left;
  width: 50%;
  padding-right: 15px;
}
.activity_l h5 {
  font-size: 14px;
}
.activity_l span {
  float: left;
  width: 100%;
  padding-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #bcbcbc;
  line-height: 120%;
}
.activity_l span.time {
  background: url(../images/ic-clock.svg) no-repeat left center;
  background-size: 14px;
  padding: 1px 0 1px 25px;
  margin-top: 5px;
  font-weight: 600;
  color: #93a1ad;
}
.activity_r {
  float: right;
  width: auto;
  margin-top: 18px;
}
.progress_main {
  display: flex;
  justify-content: flex-end;
  float: left;
  width: auto;
}
.progress {
  float: left;
  border-radius: 3px;
  width: 55px;
  height: 3px;
  background: #dedede;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
/*.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: #46a546;
}

.progress .progress-bar.fil-rouge {
  background-color: red;
}*/
.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}
.progress-bar {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.social_data .progress .progress-bar {
  float: right;
  animation-name: animateLeft;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}
@keyframes animateLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.progress_main span {
  float: left;
  width: auto;
  margin: 2px 15px 0;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: 600;
  line-height: 120%;
}
.outof_point {
  background: #13d60f;
  float: left;
  width: 50px;
  padding: 4px 5px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  border-radius: 100px;
  text-align: center;
}
.activity_tab .activity_list ul li:hover {
  background: #ffffff;
}
.activity_btn {
  background: #e4eaed;
  float: left;
  width: 100%;
  height: 30px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
}
.activity_btn:hover {
  text-decoration: none;
}
.activity_btn span {
  display: inline-block;
  width: auto;
  padding-left: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #6e6e6e;
  line-height: 120%;
  position: relative;
  letter-spacing: 0;
}
.graph img {
  width: 100%;
  cursor: pointer;
}
.top_point {
  padding: 70px 0;
}
.top_point .left_con {
  padding: 10px 30px 0 15px;
  position: relative;
}
.top_point .left_con p {
  padding-top: 20px;
}
.top_point .left_con:before {
  background: url(../images/top-point-sep.svg) no-repeat center center;
  position: absolute;
  right: -40px;
  bottom: -115px;
  width: 200px;
  height: 180px;
  background-size: 100%;
  content: '';
  z-index: -9;
}
.title {
  float: left;
  width: 100%;
}
.title h6 {
  border-bottom: 1px solid #6e6e6e;
  padding-bottom: 5px;
  color: #6e6e6e;
  font-weight: 600;
  text-transform: uppercase;
}
.title p {
  padding-top: 10px;
}

.selling_points {
  padding-top: 30px;
}
.selling_list label {
  float: left;
  width: auto;
  padding: 7px 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
}
.selling_list ul li {
  float: left;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  overflow: hidden;
}
.selling_list ul li:first-child {
  margin: 0;
  border-radius: 0 5px 5px 5px;
  -webkit-border-radius: 0 5px 5px 5px;
  -moz-border-radius: 0 5px 5px 5px;
}
.sell_point_raw {
  display: flex;
  justify-content: space-between;
  float: left;
  background: #ffffff;
  width: 100%;
  height: auto;
  padding: 20px 40px 20px 20px;
  position: relative;
  behavior: url(PIE.htc);
}
.sell_point_raw .number {
  width: 30px;
  height: 30px;
  font-weight: 700;
}
.sell_info {
  display: flex;
  align-items: center;
}
.sell_no {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  behavior: url(PIE.htc);
  position: relative;
}
.sell_des {
  padding: 0 15px;
}
.sell_des h6 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.sell_des p {
  padding-top: 5px;
  color: #828282;
  line-height: 120%;
}
.sell_type {
  float: left;
  width: 71%;
}
.selling_list .sell_type ul li {
  float: left;
  width: 20%;
  padding: 0 20px;
  margin: 0;
  text-align: center;
  border: none;
}
.sell_type h6 {
  font-weight: 400;
  color: #919191;
}
.sell_type span {
  display: inline-block;
  width: auto;
  padding-top: 5px;
  font-size: 16px;
  /*color: #000;*/
  line-height: 120%;
}
.sell_point_raw .sell_arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 100%;
  content: '';
  background: #8e54e9 url(../images/sell-arrow.svg) no-repeat right 10px center;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.sell_point_raw a:hover {
  background-position: right 5px center;
  background-color: #813ee6;
}
.sell_point_raw:hover a {
  background-position: right 5px center;
  background-color: #813ee6;
}
.sell_point_raw:hover {
  cursor: pointer;
}
.more {
  float: right;
  width: auto;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.more:hover {
  background-position: right center;
}
.statistiques {
  padding: 0 0 60px;
}
.statistiques_main {
  float: left;
  width: 100%;
  position: relative;
}
.statistiques_tab {
  float: left;
  width: 100%;
  padding-top: 20px;
  text-align: center;
}
.statistiques_tab .nav-tabs {
  background: #ffffff;
  position: absolute;
  width: auto;
  right: 68px;
  top: -80px;
}

.statistiques_tab .nav-tabs li a {
  padding: 10px 30px;
  min-width: 160px;
}

.nav-tabs span {
  position: absolute;
  width: 15px;
  left: 30px;
  top: 11px;
}
.nav-tabs li.google span {
  left: 38px;
}

.statistiques_con {
  float: left;
  width: 100%;
  position: relative;
}
.statistiques_con p {
  color: #273138;
}
.statistiques_con h2 {
  font-size: 48px;
  font-weight: 400;
}
.statistiques_con span {
  /* float: left;*/
}
.statistiques_con .progress {
  float: none;
  display: inline-block;
  width: 55%;
  height: 40px;
  margin-top: 25px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  position: relative;
}
.statistiques_con .progress-bar {
  font-size: 14px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  padding-left: 20px;
  border-radius: inherit;
}
.statistiques_con .budeget_data {
  width: auto;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 12px;
}
.google_budgtes {
  text-align: left;
  padding-top: 20px;
}
.budgtes_box {
  background: #eff3f7;
  float: left;
  width: 100%;
  padding: 30px 35px;
  margin-top: 30px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.number {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
}
.budgtes_box h3 {
  padding-top: 10px;
}
.budget_type {
  float: left;
  width: 100%;
  padding-top: 15px;
}
.budget_type .spanBudgetType {
  width: auto;
  padding: 0 20px 0 25px;
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
}
.budget_type span:last-child {
  padding-right: 0;
}
.budget_type span.impressions {
  background: url(../images/ic-impressions.svg) no-repeat left center;
  background-size: 14px;
}
.budget_type span.clics {
  background: url(../images/ic-clics.svg) no-repeat left center;
  background-size: 11px;
}
.budget_type span.conversions {
  background: url(../images/ic-conversions.svg) no-repeat left center;
  background-size: 14px;
}
.budeget_des {
  float: left;
  width: 100%;
  padding-top: 10px;
}
.budeget_des ul li {
  float: left;
  width: 100%;
  padding-top: 8px;
  font-size: 12px;
  color: #6e6e6e;
  line-height: 120%;
}
.budeget_des .spanBudeget_des {
  width: 40%;
  padding-right: 35px;
}
.budeget_des strong {
  font-size: 13px;
}

/*#facebook .statistiques_con .progress-bar {
  background: linear-gradient(0deg, #3b5998, #3b5998);
}*/
.socialchar_tab {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 40px;
  text-align: center;
}
.socialchar_tab .nav-tabs {
  background: transparent;
  top: 0;
}
.socialchar_tab img {
  display: inline-block;
  width: 64%;
}
.statistiques_tab .socialchar_tab .nav-tabs li {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  margin: 0 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.statistiques_tab .socialchar_tab .nav-tabs li a:before {
  display: none;
}
.statistiques_tab .socialchar_tab .nav-tabs li a {
  padding: 0;
  min-width: inherit;
  height: 100%;
  line-height: 24px;
}
.statistiques_tab .socialchar_tab .nav-tabs li a svg {
  display: inline-block;
  vertical-align: middle;
  fill: #93a1ad;
}
.statistiques_tab .socialchar_tab .nav-tabs li.active a svg {
  fill: #13d60f;
}

.facebook_budget,
.google-ads_budget {
  float: left;
  width: 100%;
}
.facebook_budget ul li,
.google-ads_budget ul li {
  float: left;
  width: 31%;
  margin: 42px 3.5% 0 0;
}
.facebook_budget ul li:nth-child(3n),
.google-ads_budget ul li:nth-child(3n) {
  margin-right: 0;
}
.facebook_budget .spent_des,
.facebook_budget .spent_box h3 {
  color: #3b5998;
}
.google-ads_budget .spent_des,
.google-ads_budget .spent_box h3 {
  color: #34a853;
}

.general_budget {
  float: left;
  width: 100%;
}
.general_budget ul li {
  float: left;
  margin: 20px;
  min-width: 15%;
  background: #ffffff;
}
.general_budget ul li:nth-child(3n) {
  margin-right: 0;
}

.general_spent_box {
  margin: 20px;
  border-radius: 3px;
  min-width: 20%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  /*height: 205px;*/
}

.general_spent_top {
  width: 100%;
  height: 110px;
  position: relative;
}

.general_spent_top span.icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.general_spent_top span img {
  display: inline-block;
}
.general_spent_des {
  padding: 20px;
  text-align: left;
}
.general_spent_top h3 {
  font-size: 32px;
  padding-top: 45px;
  text-transform: capitalize;
}
.general_spent_des h5 {
  font-size: 16px;
  font-weight: 600;
}
.general_spent_des p {
  padding-top: 5px;
  color: #93a1ad;
  line-height: 130%;
}

.spent_box {
  background: #ffffff;
  float: left;
  padding: 20px 25px 15px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  /*height: 205px;*/
}
.spent_top {
  float: left;
  width: 100%;
  height: 110px;
  position: relative;
  overflow: hidden;
}
.spent_top span.icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.spent_top span img {
  display: inline-block;
}
.spent_des {
  float: left;
  width: 100%;
  text-align: left;
}
.spent_top h3 {
  font-size: 32px;
  padding-top: 45px;
  text-transform: capitalize;
}
.spent_des h5 {
  font-size: 16px;
  font-weight: 600;
}
.spent_des p {
  padding-top: 5px;
  color: #93a1ad;
  line-height: 130%;
}
#chart {
  float: left;
  width: 100%;
  height: auto;
}
#chart #bars {
  float: none;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}
#chart #bars li {
  display: table-cell;
  width: 44px;
  height: 70px;
  margin: 0;
  text-align: center;
  position: relative;
}
#chart #bars li .bar {
  display: block;
  width: 15px;
  margin: 0 auto;
  background: #d1d1d1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#chart #bars li .bar:before {
  color: #93a1ad;
  font-size: 11px;
  content: attr(data-percentage);
  position: relative;
  bottom: 20px;
  left: -2px;
}
#chart #bars li.high .bar:before {
  color: #3b5998;
}
#chart #bars li.high .bar {
  background: #3b5998;
}
#chart #bars li span {
  color: #eee;
  width: 100%;
  position: absolute;
  bottom: -2em;
  left: 0;
  text-align: center;
  font-size: 11px;
  color: #93a1ad;
  font-weight: 400;
  line-height: 120%;
}

/*-----sidebar-----*/
.sidebar {
  float: right;
  width: 32%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  right: 0;
  top: 80px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  transform: translateX(102%);
  z-index: 99;
}
.notification.active .sidebar {
  transform: none;
}
.notification_main {
  background: #ffffff;
  float: left;
  width: 100%;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.noti_top {
  float: left;
  width: 100%;
  padding: 25px 45px 15px;
}

.noti_head {
  float: left;
  width: 100%;
}
.noti_head h4 {
  width: auto;
  font-size: 18px;
  color: #93a1ad;
  position: relative;
}
.header_right .sidebar .noti_head span.noof_noti {
  right: -50px;
  top: -3px;
  left: inherit;
}
.noti_head .close_noti {
  background: url(../images/ic-close.svg) no-repeat center;
  cursor: pointer;
  float: right;
  width: 20px;
  height: 20px;
  margin-top: 3px;
}
.campaigns_con {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 25px;
}
.campaigns_con ul {
  width: 100%;
}
.header_right .nav_main .campaigns_con ul li {
  width: 50%;
  border: none;
}
.campaigns_con h6 {
  float: none;
  display: inline-block;
  padding-top: 10px;
  font-size: 12px;
  color: #6e6e6e;
}
.campaigns_con span.campaigns_ico {
  display: inline-block;
  width: auto;
  height: 40px;
  line-height: 40px;
}
.campaigns_con span img {
  display: inline-block;
  vertical-align: top;
}
.campaigns_con span {
  display: inline-block;
  width: 100%;
  font-size: 11px;
  color: #9fa5ac;
  font-weight: 500;
  line-height: 120%;
}
.campaigns_con .progress_main {
  float: none;
  display: inline-block;
}
.campaigns_con .progress {
  float: none;
  display: inline-block;
  width: 115px;
}
.campaigns_con .progress_main span {
  float: right;
  width: 80%;
  margin: 12px 20% 0 0;
  text-align: right;
}
.medal_list {
  float: left;
  width: 100%;
  padding-top: 10px;
}
.header_right .campaigns_con .medal_list ul li {
  float: none;
  display: inline-block;
  margin: 0 1px;
  width: auto;
}
.medal_list ul li img {
  display: inline-block;
}
.notification_list {
  float: left;
  width: 100%;
  height: calc(100vh - 340px);
  overflow: auto;
  transition: all 0.5s ease 0s;
}
body.closed .notification_list {
  height: calc(100vh - 263px);
}
.notification_list ul {
  width: 100%;
}
.header_right .nav_main .notification_list li {
  background: url(../images/list-sep.svg) no-repeat left 25px center;
  width: 100%;
  padding: 12px 10% 12px 14%;
  border: none;
  position: relative;
  text-align: left;
}
.header_right .notification_list li.today {
  background: url(../images/notification-clock.svg) no-repeat left 25px center;
}
.header_right .notification_list li.today:after {
  width: 5px;
  height: 5px;
  content: '';
  position: absolute;
  left: 10px;
  top: 28px;
  background: #2f80ed;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}
.header_right .notification_list li:before {
  width: 86%;
  height: 1px;
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  background: #dbdbdb;
}
.notification_list li:last-child:before {
  display: none;
}
.notification_list h6 {
  font-size: 12px;
  text-align: center;
  padding: 15px 0 0;
  text-transform: uppercase;
}
.notification_list p {
  float: none;
  display: inline-block;
  width: 85%;
  font-size: 13px;
  line-height: 140%;
}
.header_right .sidebar .notification_list p a {
  float: none;
  width: auto;
  color: #8e53e9;
  padding: 0;
  letter-spacing: 0;
  display: inline-block;
  line-height: 140%;
  font-size: 13px;
  font-weight: 400;
  text-transform: inherit;
}
.notification_list span.time {
  float: right;
  width: auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 35px;
  color: #b8b8b8;
}
.sidebar .notification_main .activity_btn {
  padding: 0;
  width: 100%;
  height: 55px;
  position: relative;
  line-height: 50px;
  text-transform: inherit;
}
/*-----sidebar-----*/

/*-----menu-----*/
.hamburger {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 99;
  opacity: 0;
  display: none;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  overflow: auto;
}
.hamburger.active {
  display: block;
  opacity: 1;
  overflow: hidden;
}
.menu_con {
  bottom: 0;
  float: left;
  left: 50%;
  position: absolute;
  top: 53%;
  transform: translate(-50%, -50%);
  width: 76%;
}
.menu_main {
  float: left;
  width: 100%;
  text-align: center;
}

.menu_main ul {
  width: 100%;
}
.menu_main ul li {
  float: left;
  width: 33.3%;
  height: 100vh;
  padding: 0 3%;
  position: relative;
  border: none;
  transition: all 0.25s ease 0s !important;
  -webkit-transition: all 0.25s ease 0s !important;
  -moz-transition: all 0.25s ease 0s !important;
}
.menu_main ul li:hover {
  background: #f7f8fa;
  cursor: pointer;
}

/*.hamburger.active .menu_main ul li:nth-child(1){
	transform: translate3d(0px, 0px, 0px);
	transition: all 0.3s ease-out 0.2s !important;
}
.hamburger.active .menu_main ul li:nth-child(2){
	transform: translate3d(0px, 0px, 0px);
	transition: all 0.3s ease-out 0.3s !important;
}
.hamburger.active .menu_main ul li:nth-child(3){
	transform: translate3d(0px, 0px, 0px);
	transition: all 0.3s ease-out 0.4s !important;
}
.hamburger.active .menu_main ul li:nth-child(4){
	transform: translate3d(0px, 0px, 0px);
	transition: all 0.3s ease-out 0.5s !important;
}*/
.menu_main ul li:last-child {
  margin-right: 0;
}
.menu_main ul li span {
  float: left;
  width: 100%;
  height: 165px;
}
.menu_main ul li span img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hamburger .menu_main a {
  float: none;
  width: auto;
  padding: 0;
  margin: 25px 0 0;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  background: none;
  line-height: 120%;
  text-transform: uppercase;
  color: #273138;
  letter-spacing: 0;
}
.hamburger .menu_main ul.has_sub {
  border-top: 1px solid #878787;
  padding-top: 15px;
  margin-top: 20px;
}
.hamburger .menu_main ul.has_sub li {
  width: 100%;
  text-align: left;
  padding: 0;
  transform: none;
  height: auto;
}
.hamburger .menu_main ul.has_sub li a {
  background: url(../images/menu-errow.svg) no-repeat right 5px center;
  width: 100%;
  padding: 0 20px 0 0;
  margin: 0;
  text-align: left;
  font-size: 14px;
  margin-top: 12px;
  font-weight: 400;
  text-transform: inherit;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.hamburger .menu_main ul.has_sub li:hover a,
.hamburger .menu_main ul.has_sub li.active a {
  background-position: right center;
}
/*-----menu-----*/
.basket_wrap.open {
  transform: scale(1);
}
.basket_wrap {
  background: #ffffff;
  width: 280px;
  padding: 30px 25px 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 99;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  transform: scale(1, 0);
  transform-origin: top;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.basket_wrap p {
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
}
.nav_main ul.cart_item {
  float: left;
  width: 100%;
}
.header_right .nav_main ul.cart_item li {
  float: left;
  width: 100%;
  margin-top: 15px;
  border: none;
}
ul.cart_item .imgwrap {
  float: left;
  width: 68px;
  height: 78px;
}
ul.cart_item .imgwrap img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.item_des {
  float: left;
  width: 68%;
  padding: 8px 0 0 20px;
  text-align: left;
}
.item_des h6 {
  color: #273138;
  font-weight: 600;
}
.quantity_count {
  float: left;
  width: 82%;
  padding: 8px;
  margin-top: 10px;
  line-height: 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.quantity_count .qty {
  display: inline-block;
  width: 50px;
  border: none;
  text-align: center;
  color: #273138;
}
.quantity_count .btn-number {
  width: 18px;
  height: 18px;
  padding: 0;
  line-height: 10px;
  vertical-align: top;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.basket_wrap .btn-primary {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  line-height: 50px;
  font-size: 14px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  text-align: center;
  color: #ffffff;
}
.qty_btn {
  box-shadow: none;
  border: none;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.hiddenRow {
  padding: 0 !important;
}
/*-----prfile-----*/
.prfile_wrap {
  background: #ffffff;
  width: 235px;
  padding: 10px 20px 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: calc(-2 * 100vw / 100);
  top: 80px;
  z-index: 9;
  transform: scale(1, 0);
  transform-origin: top;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.prfile_wrap.open {
  transform: none;
}
.header_right .prfile_wrap ul li {
  float: left;
  width: 100%;
  border: none;
  margin-top: 12px;
}
.header_right .prfile_wrap ul li a,
.header_right .prfile_wrap ul li span.username {
  font-size: 14px;
  padding: 5px 0 5px 30px;
  line-height: 120%;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.header_right .prfile_wrap ul li.pers_info a,
.header_right .prfile_wrap ul li.pers_info span.username {
  background: url(../images/profile-user.svg) no-repeat left center;
}
.header_right .prfile_wrap ul li.logout a {
  background: url(../images/ic-logout.svg) no-repeat left center;
  color: #93a1ad;
}
.header_right .prfile_wrap ul li a:hover {
  padding: 5px 0 5px 35px;
}
/*-----prfile-----*/
.footer {
  background: #93a1ad;
  padding: 15px 0;
  text-align: center;
  bottom: 0;
  left: 0;
}
.footer .container {
  position: relative;
}
.footer p {
  width: auto;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
.copy_right {
  float: none;
  display: inline-block;
  padding-left: 20px;
  background: url(../images/logo-whire.svg) no-repeat left center;
}
.footer p.powered {
  right: 15px;
  top: 0;
}
.footer p.powered span {
  display: inline-block;
  background: url(../images/footer-heart.png) no-repeat center;
  background-size: 100%;
  margin: 0 3px;
  width: 10px;
  height: 10px;
}

/* index end  */
/* ------ Registration-start ------ */
.visiretail_registration {
  overflow: hidden;
  height: 100%;
}
.LoginWraper section {
  margin: 0;
}
.registration_main {
  padding: 50px 15px 50px 4%;
  position: relative;
}
/* .registration_main:before {
  background: url(../images/logo-sep.svg) no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: -55px;
  top: 30px;
  width: 280px;
  height: 252px;
  content: "";
} */
.registration_head {
  float: right;
  width: auto;
}
.registration_head span {
  float: left;
  width: auto;
  font-size: 14px;
  color: #6e6e6e;
  line-height: 120%;
}
.registration_head .btn-outline-primary.login {
  float: right;
  margin: -10px 0 0 22px;
}
.registration_con {
  float: left;
  width: 100%;
  padding: 52px 15% 0 0;
}
.registration_con .logo {
  margin: 0;
}
.registration_con h1 {
  padding-top: 25px;
  font-weight: 600;
  /* font-family: Circular Std; */
  font-size: 36px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #273138;
}

.form_main {
  float: left;
  width: 100%;
  /*padding-top: 35px;*/
}
.form_error {
  color: red;
}
.form_raw {
  float: left;
  width: 100%;
  padding-top: 12px;
}
.form_raw label {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  line-height: 120%;
}
.form_main .btn-primary,
.form_main .btn-submit {
  width: 100%;
  height: 56px;
  margin: 25px 0 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
}
.form_main .fogot {
  float: right;
  width: auto;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  font-style: italic;
}
.form_col {
  float: left;
  width: 48%;
  margin-right: 3%;
}
.form_col:last-child {
  margin: 0;
}
.registr_slider img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
textarea.form-control {
  height: 110px;
  padding: 10px 15px;
}
.registr_slider .carousel {
  height: 100vh;
  position: relative;
}
.carousel-inner .item {
  height: 100%;
}
.carousel-control {
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  opacity: 1;
}
.carousel-inner {
  cursor: all-scroll;
  height: 100%;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.active.left,
  .carousel-fade .carousel-inner > .item.active.right,
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.prev {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.active,
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.registr_slider {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 0;
}
/* .registr_slider:before {
  background: url(../images/form-sep.svg) no-repeat center;
  position: absolute;
  left: -220px;
  top: 37%;
  width: 320px;
  height: 350px;
  background-size: 100%;
  content: "";
  z-index: -9;
} */
.registr_slider h2 {
  position: absolute;
  left: 0;
  bottom: 145px;
  color: #ffffff;
  padding: 0 5%;
}
.registr_slider .mySlides:before {
  /*background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.48) 39.96%,
    rgba(0, 0, 0, 0) 100%
  );*/
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
}

.slide_main {
  float: left;
  width: 100%;
  height: 100vh;
  position: relative;
}

.mySlides {
  display: none;
  width: 100%;
  height: 100vh;
  position: relative;
}
.indicators {
  width: auto;
  left: 0;
  bottom: 100px;
  margin-left: 5%;
  position: absolute;
}
.dot {
  float: left;
  background: rgba(255, 255, 255, 0.5);
  width: 50px;
  height: 3px;
  margin: 0 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  cursor: pointer;
}
.dot.active {
  background: #8e54e9;
}

/* ------ Registration-end ------ */

/* ------ Launch-start ------ */
.imgwrap,
.textwrap {
  float: left;
  width: 100%;
  position: relative;
}
.imgwrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.launch_campaign {
  padding-bottom: 50px;
}

.launch_campaign .sms_head {
  position: relative;
  top: inherit;
  margin-top: 25px;
}
.launch_campaign .sms_head p {
  padding-top: 15px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}

.sms_head .local_ico {
  float: left;
  width: auto;
  position: relative;
}
.sms_head .local_ico img {
  display: inline-block;
}
.sms_head .local_ico h5 {
  float: none;
  display: inline-block;
  width: auto;
  margin-left: 27px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.progrss_main {
  /* float: left;*/
  padding-top: 40px;
}
.progrss_main h5 {
  color: #93a1ad;
}
.progrss_box {
  float: left;
  width: 100%;
  margin-top: 30px;
  position: relative;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.progrss_box .imgwrap {
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  behavior: url(PIE.htc);
  position: relative;
  overflow: hidden;
}
.progrss_box .imgwrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  behavior: url(PIE.htc);
  position: relative;
}
.progrss_box .textwrap {
  background: #ffffff;
  height: auto;
  padding: 30px 15px;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  behavior: url(PIE.htc);
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.progrss_box.campaign {
  padding-bottom: 150px;
}
.progrss_box.campaign .textwrap {
  position: absolute;
  left: 0;
  bottom: -213px;
  height: 363px;
}
.progrss_box:hover .textwrap {
  bottom: 0;
}
.cate {
  background: #0fb2d6;
  position: absolute;
  left: 0;
  top: 45px;
  padding: 8px;
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}
.cate:before {
  position: absolute;
  right: -14px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 0 14px 14px;
  border-color: transparent transparent transparent #0fb2d6;
}
.cate.popular:before {
  border-color: transparent transparent transparent #e7970c;
}
.cate.popular {
  background: #e7970c;
}

.textwrap,
.imwrap {
  float: left;
  width: 100%;
}
.campagene_head {
  float: left;
  width: 100%;
  position: relative;
}
.campagene_title h6 {
  color: #273138;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
}
.campagene_head p {
  font-size: 12px;
}
.campagene_title {
  float: left;
  width: 100%;
  padding-right: 33%;
  position: relative;
  height: 50px;
}
.campagene_title .productDates {
  float: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  clear: both;
}
.campagene_title .ttc {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.campagene_title .ttc-disable {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px !important;
  font-weight: 600 !important;
}

.progress_raw {
  float: left;
  width: 100%;
  padding-top: 35px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.progress_raw h6 {
  font-size: 11px;
  color: #273138;
}
ul.social_list li {
  float: left;
  width: auto;
  margin: 15px 30px 0 0;
  position: relative;
}
ul.social_list li:last-child,
.campain_right ul.social_list li:last-child {
  margin-right: 0;
}
ul.social_list li.checked .support_ico:before {
  background: url(../images/ic-checked.svg) no-repeat center;
  background-size: 100%;
  width: 10px;
  height: 100%;
  position: absolute;
  right: -5px;
  bottom: -10px;
  content: '';
}
ul.social_list .support_ico {
  float: left;
  width: 20px;
  height: 20px;
  position: relative;
}

ul.pack_list {
  padding-top: 5px;
}
.pack_list li {
  background: url(../images/right-tic.svg) no-repeat left top 3px;
  float: left;
  width: 100%;
  padding-left: 20px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
  line-height: 120%;
}

.offer {
  position: absolute;
  left: 0;
  top: -25px;
  float: left;
  width: 100%;
}
.offer span {
  background: #2f80ed;
  float: left;
  width: 14%;
  margin-right: 1%;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 120%;
  padding: 5px 20px;
  min-height: 25px;
  border-radius: 5px 0 0 0;
}
.offer span:last-child {
  float: right;
  width: 85%;
  margin: 0;
  border-radius: 0 5px 0 0;
}

.shortly .progrss_box .imgwrap h5 {
  padding: 0 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 9;
}
.shortly .progrss_box .imgwrap:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 9;
}
.shortly .progrss_box {
  padding: 0;
}
.shortly .progrss_box .textwrap {
  height: auto;
  position: relative;
}
.shortly .campagene_head h6 {
  padding-bottom: 5px;
}
.shortly .campagene_head h6,
.shortly .campagene_head span {
  color: #8e8e8e;
}
/* ------ Launch-end ------ */

.description_box {
  float: left;
  width: 100%;
  background: #e4ebf2;
  padding: 130px 10% 50px 7%;
  margin-top: 30px;
  border-radius: 48px;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  behavior: url(PIE.htc);
  position: relative;
}
.campain_left {
  padding-right: 30px;
}
.campain_det .imgwrap {
  height: 275px;
}
.campain_det .imgwrap img {
  border-radius: 12px;
}
.campain_det ul {
  padding-top: 25px;
}
.campain_det ul li {
  float: left;
  width: 100%;
  margin-top: 20px;
  padding-left: 25px;
  font-size: 13px;
  font-weight: 400;
  color: #273138;
  line-height: 120%;
  position: relative;
}
.campain_det ul li .little-icon {
  float: left;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 5px;
}
.campain_left .camain_action {
  padding-top: 30px;
}
.campain_left .camain_action .btn-secondary {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  margin: 10px 0 0;
}
.campain_right {
  padding: 15px 55px 0;
}
.campain_des h6 {
  text-transform: uppercase;
}
.campain_des .date {
  font-size: 14px;
}
.date {
  float: left;
  width: 100%;
  padding-top: 10px;
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
}
.campain_des p {
  padding-top: 20px;
  color: #273138;
}
.camain_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.camain_buttons {
  margin: 10px 0;
}
.camain_action .camain_buttons button:not(:first-child) {
  margin-left: 1rem;
}
.camain_action .btn-secondary,
.camain_action .btn-outline-primary,
.camain_action .btn-primary {
  height: 48px;
  line-height: 44px;
  padding: 0 40px;
  /*margin: 25px 20px 0 0;*/
  font-size: 15px;
  text-transform: inherit;
  font-weight: 600;
}
.camain_action .btn-outline-primary {
  border-radius: 25px;
}
.price {
  float: right;
  width: auto;
  /*margin-top: 32px;*/
  color: #273138;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
}
.campain_right ul.social_list li {
  margin: 15px 10px 0 0;
  text-align: center;
}
.campain_right ul.social_list .support_ico {
  float: none;
  display: inline-block;
  width: auto;
  height: 32px;
}
.campain_right ul.social_list li.checked .support_ico:before {
  width: 18px;
  bottom: -15px;
}
.campain_right .social_list span {
  float: left;
  width: 100%;
  margin-top: 12px;
  color: #9fa5ac;
  font-weight: 500;
  font-size: 11px;
  line-height: 120%;
}
.campain_right .social_list li.checked span {
  color: #273138;
}
.all_media {
  background: url(../images/ic-info.svg) no-repeat left;
  float: left;
  width: auto;
  padding: 2px 0 2px 30px;
  margin-top: 20px;
  font-weight: 500;
  color: #4679ea;
  font-size: 12px;
  line-height: 120%;
}
.campaign_raw {
  float: left;
  width: 100%;
  padding-top: 60px;
}
.campaign_raw h6 {
  color: #273138;
  font-weight: 600;
}
.broadcast_campaign ul li {
  float: left;
  width: 18%;
  margin: 20px 2.5% 0 0;
  text-align: center;
}
.broadcast_campaign ul li a {
  float: left;
  background: #ffffff;
  width: 100%;
  height: 62px;
  line-height: 58px;
}
.broadcast_campaign ul li a img {
  display: inline-block;
  vertical-align: middle;
}
.broadcast_campaign ul li:last-child {
  margin-right: 0;
}

.panel-group {
  float: left;
  width: 100%;
  height: auto;
  margin: 20px 0 0;
}
.panel-default {
  float: left;
  width: 100%;
  height: auto;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  position: relative;
}
.panel-heading {
  background: none;
  background-image: none;
  padding: 0;
}
.panel-title {
  float: left;
  width: 100%;
}
.panel-title a {
  background: #ffffff;
  padding: 12px 20px 12px 60px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 120%;
  position: relative;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.panel-title a.collapsed {
  background: #dee4ea;
}
.panel-heading {
  float: left;
  width: 100%;
  height: auto;
}
.panel-heading a {
  float: left;
  width: 100%;
  text-decoration: none;
}
.panel-body {
  background: #fff;
  float: left;
  width: 100%;
  height: auto;
  padding: 10px 60px 40px;
  border: none !important;
  position: relative;
}
.panel-body p {
  padding: 10px 0 0;
  color: #7b7b7b;
}
.panel-body img {
  padding-top: 20px;
}
.panel-collapse {
  float: left;
  width: 100%;
  height: auto;
}
.panel-title a span {
  background: url(../images/faq-close.svg) no-repeat center center;
  float: right;
  height: 100%;
  position: absolute;
  left: 10px;
  top: 0;
  width: 40px;
}
.panel-title a.collapsed span {
  background: url(../images/faq-open.svg) no-repeat center center;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}

.franchi_campaign {
  float: left;
  width: 100%;
  padding-top: 20px;
}
.franchi_campaign ul li {
  display: inline-block;
  width: 100%;
  padding: 18px 15px;
  /*height: 122px;*/
  line-height: 80px;
  border-bottom: 1px solid #c6cbd1;
}
.franchi_campaign .imgwrap {
  width: 85px;
  height: 85px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.franchi_campaign .imgwrap img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.franchi_campaign .textwrap {
  float: none;
  display: inline-block;
  width: 27%;
  padding: 0 20px;
  vertical-align: middle;
}
.franchi_campaign .textwrap .date {
  padding-top: 5px;
}
.franchi_campaign .cate {
  display: inline;
  position: relative;
  left: inherit;
  top: inherit;
}
.franchi_campaign .cate:before {
  border-width: 15px 0 14px 14px;
  right: -14px;
}
.franchi_campaign .price {
  float: none;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
.franchi_raw {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}
.franchi_raw.camaign_per {
  width: 10%;
}
.franchi_raw.cap_cate {
  padding-right: 60px;
  text-align: right;
}
.franchi_raw.camaign_per span {
  background: url(../images/ic-user.svg) no-repeat left center;
  padding: 5px 0 5px 25px;
  color: #273138;
  font-size: 13px;
}
.franchi_raw.camaign_price {
  float: right;
  width: auto;
}

.franchi_raw .day_camp {
  background: url(../images/notification-clock.svg) no-repeat left top 5px;
  padding: 0 20px;
  line-height: 120%;
}
.franchi_raw .day_camp span {
  color: #f14338;
}
body.modal-open {
  padding: 0 !important;
}

/*  conversions  */
.head_search {
  background: #e4e9f0;
  padding: 16px 0;
  position: fixed;
  z-index: 99;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  top: 80px;
}
.serch_txt {
  border: none !important;
  padding: 0 20px 0 30px;
  height: auto;
}
.serch_txt:focus {
  border-color: transparent;
  box-shadow: none;
}

.conversions_wraper {
  padding: 25px 0 60px;
}
.conversions_wraper h1 span {
  font-size: 16px;
  color: #8e54e9;
  vertical-align: middle;
  margin-left: 15px;
}
.conversions_tab {
  float: right;
  margin-top: 8px;
}
.conversions_tab .nav-tabs li a {
  min-width: 160px;
  text-align: center;
  padding: 10px 20px;
}
.conversions_main {
  padding-top: 50px;
}

.conversions_main .table {
  width: 100%;
  position: relative;
  margin: 0;
}
table tr th button {
  background: transparent;
  text-transform: uppercase;
}
.conversions_main .table tr th {
  font-size: 14px;
  padding: 0 17px 25px;
  color: #93a1ad;
  text-transform: uppercase;
  border: none;
}
.conversions_main .table tr th i {
  margin-left: 10px;
}
.conversions_main .table tr td {
  font-size: 14px;
  color: #6e6e6e;
  border: none;
}
.conversions_main .table tr.accordion-toggle td {
  padding: 25px 15px;
}
.conversions_main .table tr.accordion-toggle td:first-child {
  width: 9%;
}
.conversions_main .table tr.accordion-toggle td:last-child {
  width: 35%;
}
.conversions_main .table tr td a {
  color: #6e6e6e;
}
.conversions_main .table tr.accordion-toggle {
  background: #ffffff;
  position: relative;
}
.conversions_main .table tr.accordion-toggle td:last-child {
  padding-right: 40px;
  position: relative;
}

td.convrs_con {
  width: 100%;
  padding: 0 20px !important;
}
.conversions_main tr td.purple,
.conversions_main tr td.uper {
  text-transform: uppercase;
}
.conversions_main .table tr.accordion-toggle td span {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 15px;
  top: 25px;
}
.conversions_main thead tr {
  position: relative;
}
.conversions_main tr.accordion-toggle td span {
  background: url(../images/ic-sub-hover.svg) no-repeat center #8e54e9;
  cursor: pointer;
}
.conversions_main tr.accordion-toggle.collapsed td span {
  background: url(../images/ic-add-hover.svg) no-repeat center #8e54e9;
  cursor: pointer;
}
.conversions_main thead tr:after {
  background: transparent;
}
.accordian-body {
  border-top: 1px solid #d7d7d7;
}
.convrs_con h5 {
  font-size: 16px;
}
.convrs_con p {
  font-size: 14px;
  padding-top: 8px;
}
.con_l {
  float: left;
  width: 75%;
  padding: 20px 15px 30px 0;
}
.con_r {
  display: inline-block;
  width: auto;
  line-height: 140px;
  vertical-align: middle;
}
.con_r a.email {
  background: url(../images/envoyer-emial.svg) no-repeat left;
  padding-left: 35px;
  font-size: 14px;
  color: #6e6e6e;
  font-weight: 500;
  line-height: 120%;
}
tr.accordion-toggle.collapsed + tr {
  background: transparent !important;
  border: none;
}
tr.accordion-toggle + tr {
  background: #ffffff !important;
  border-bottom: 20px solid #f6f8fa;
}
/*  conversions  */

/*  overlay */
.campagne_wraper {
  float: left;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 9;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
}
.campagne_wraper.active {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
}
.campagne_main {
  float: left;
  width: 100%;
  background: #ffffff;
  padding: 70px 7% 50px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  position: relative;
}
.campagne_main:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  content: '';
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
}
.campagne_main.rentree:before {
  background: #4679ea;
}
.campagne_main.toussaint:before {
  background: #ff248d;
}
.campagne_main.vacances:before {
  background: #efb30c;
}
.ovelay_left {
  float: left;
  width: 40%;
}
.ovelay_left h6 {
  text-transform: uppercase;
}
.ovelay_left h2 {
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 10px;
}
.campagne_main .progress_main {
  width: 100%;
  margin-top: 15px;
}
.campagne_main .progress_main h6 {
  width: 27%;
  padding-right: 30px;
  text-transform: uppercase;
}
.campagne_main .progress {
  width: 125px;
  height: 5px;
}
.campagne_main .progress_main span {
  font-size: 14px;
  color: #93a1ad;
  font-weight: 500;
}
/*.campagne_main .progress_main .progress-bar {
  background: #8e54e9;
}*/
.ovelay_center {
  float: left;
  width: 30%;
}
.ovelay_right {
  float: right;
  width: 40%;
}
.campagne_main .social_data .progress_main h6 {
  width: 22%;
  padding-right: 15px;
  color: #273138;
  font-weight: 400;
  text-align: right;
}
.campagne_main .social_data .progress {
  width: 270px;
  height: 22px;
  margin: -2px 0 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.social_data .progress-bar {
  border-radius: 0;
}
.social_data .progress-bar span {
  float: right;
  margin: 4px 15px 0;
  color: #ffffff;
  font-size: 12px;
}
/*.progress_main.google .progress-bar {
  background: #34a853;
}
.progress_main.facebook .progress-bar {
  background: #3b5998;
}*/

.campagne_main .social_data .progress_main span.ico {
  width: 14px;
  margin-right: 0;
}
.overlay_list {
  float: left;
  width: 100%;
  padding-top: 50px;
}
.overlay_list ul li {
  float: left;
  width: 20%;
  padding-right: 15px;
}
.overlay_list span {
  float: left;
  width: auto;
}
span.value {
  float: left;
  width: 100%;
  padding-top: 10px;
  font-size: 28px;
  color: #273138;
  font-weight: normal;
  line-height: 120%;
}
.overlay_list h6 {
  text-transform: uppercase;
  padding-top: 25px;
}
/*  overlay */
/*  clender */
.daterangepicker {
  top: 140px !important;
  right: 1% !important;
  position: fixed !important;
  z-index: 9;
  border: 1px solid #ddd;
}
body.closed .daterangepicker {
  top: 60px !important;
}
.daterangepicker::before,
.daterangepicker::after {
  display: none;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  border-color: #ddd;
  color: #999 !important;
}
.daterangepicker .calendar-table th.prev,
.daterangepicker .calendar-table th.next {
  border: 1px solid #ddd;
}
.daterangepicker .calendar-table th.month {
  color: #383838;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.daterangepicker .calendar-table th {
  color: #8c8f93;
  text-transform: lowercase;
  border: none;
}
.daterangepicker .calendar-table td {
  border: 1px solid #dddddd;
  border-radius: 0 !important;
  font-weight: 500;
  color: #696969;
}
.daterangepicker td.in-range {
  background: rgba(19, 214, 15, 0.12);
  color: #13d60f;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: #13d60f;
  border-color: #dddddd;
  color: #ffffff;
}
.daterangepicker td.available:hover {
  border-color: #dddddd;
}
.drp-buttons {
  float: left;
  width: 100%;
  margin-top: 20px;
}

/*Calendar*/
.DateRangePicker__CalendarSelection {
  border: 1px solid #ddd !important;
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}
.DateRangePicker__CalendarSelection {
  border: 1px solid #ddd !important;
}

/*Calendar*/
.daterangepicker .calendar-buttons .btn {
  float: none;
  margin: 10px;
  display: inline-block;
  padding: 0 25px;
  height: 32px;
  line-height: 30px;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  text-transform: uppercase;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.daterangepicker .calendar-buttons .btn.btn-default {
  background: #eee;
}

.daterangepicker .drp-buttons .btn {
  float: none;
  display: inline-block;
  padding: 0 25px;
  height: 32px;
  line-height: 30px;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  text-transform: uppercase;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.daterangepicker .drp-buttons .btn.btn-default {
  background: #eee;
}
.daterangepicker .drp-selected {
  font-size: 16px;
  font-weight: 500;
  color: #273138;
}
/*  clender */

.pointserch_wrap {
  background: #edf1f5;
  height: 65px;
  padding: 0 2%;
  position: fixed;
  top: 80px;
  z-index: 9;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  overflow: hidden;
}
.sale_search {
  width: 30%;
}
.sale_search .serch_txt {
  height: 30px;
  padding: 0 20px 0 40px;
  font-size: 14px;
  font-weight: 500;
}
.autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
}
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}
.autocomplete-active {
  background-color: #9054f1 !important;
  color: #ffffff;
}
.pointsale_slider {
  float: right;
  width: 65%;
  height: 65px;
  position: relative;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.main.single_sale {
  padding: 0;
}
.campagnes_main {
  padding: 20px 0;
}
.budget_main {
  padding-bottom: 55px;
}
.budget_left {
  display: flex;
  align-items: center;
}
.budget_left span.number {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  left: 0;
  top: 1px;
}
.budget_left h1 {
  margin-left: 10px;
}
.budget_spent {
  text-align: right;
}
.budget_spent span {
  padding: 0;
}
.campagnes_main h6 {
  color: #273138;
}
.campagnes_main h6 span {
  width: auto;
  color: #b3b3b3;
  margin-left: 20px;
}
.statistiques.bg_white {
  padding: 60px 0;
  background: #ffffff;
}
.statistiques.bg_white .statistiques_tab .nav-tabs {
  top: -50px;
}
.campagnes_wrap .franchi_campaign {
  padding: 50px 0;
}
.campagnes_wrap .franchi_campaign ul li {
  padding: 20px 0;
  line-height: 160px;
  border: none;
}
.campagnes_wrap .franchi_campaign .imgwrap {
  width: 135px;
  height: 135px;
}
.campagnes_wrap .franchi_campaign .textwrap {
  width: 18%;
}
.campagnes_wrap .franchi_campaign .textwrap h6 {
  font-size: 16px;
  color: #273138;
}
.campagnes_wrap .franchi_campaign .textwrap .date {
  font-size: 14px;
  font-weight: 400;
}
.campagnes_wrap .overlay_list {
  float: none;
  display: inline-block;
  width: 70%;
  padding: 0;
  vertical-align: middle;
}
.campagnes_wrap .overlay_list ul li {
  border: none;
}
.campagnes_wrap .overlay_list h6 {
  padding-top: 10px;
  font-size: 13px;
}
.campagnes_wrap .progress_main {
  width: 100%;
  padding: 10px 0 0;
}
.campagnes_wrap .progress {
  width: 85px;
}
.campagnes_wrap .progress_main span {
  float: right;
  margin: 2px 0 0 5px;
}
.campagnes_wrap .activity_btn {
  margin-top: 20px;
}

/*.progress_main.blue .progress-bar {
  background: #4679ea;
}
.progress_main.pink .progress-bar {
  background: #ff248d;
}
.progress_main.orange .progress-bar {
  background: #eb5757;
}
.progress_main.terminate .progress-bar {
  background: #ffa012;
}*/

/* slider */
.slider-nav-thumbnails {
  height: 100%;
  display: flex;
}

.slick-slider {
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  display: none !important;
}
.slick-arrow {
  background: url(../images/ic-next-prev.svg) no-repeat center rgba(159, 165, 172, 0.8);
  width: 30px;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 9;
}
.slick-arrow.slick-prev {
  left: -30px;
}
.slick-arrow.slick-next {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  right: -30px;
}

.point_con {
  background: url(../images/slide-errow.svg) no-repeat center right 15px;
  float: left;
  height: 100%;
  width: 100%;
  border-right: 1px solid #fff;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 0 10px;
}
.budget_left .number.nbg {
  background: transparent;
  width: 30px;
  height: 30px;
}
.point_con h5 {
  font-weight: 500;
  color: #a2b0bc;
  margin-bottom: 3px;
}
.point_con h6 {
  color: #273138;
}
.slick-current .point_con {
  background: url(../images/slide-active.svg) no-repeat center right 15px #ffffff;
  cursor: default;
}
/* slider */

/* campagnes */

.main_wraper {
  padding-bottom: 80px;
  position: relative;
}
.mescampagn_wraper {
  float: left;
  width: 100%;
  padding-top: 20px;
}
.mescampagn_wraper .campagne_main {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.mescampagn_wraper .campagne_wraper {
  position: relative;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  left: inherit;
  margin-top: 30px;
  z-index: 0;
}
.mescampagn_wraper .ovelay_left {
  width: 30%;
}
.mescampagn_wraper .ovelay_left h2 {
  font-size: 26px;
  margin-top: 2rem;
}
/*.mescampagn_wraper .ovelay_center .progress_main .progress-bar {
  background: #00cc47;
}

.mescampagn_wraper
  .ovelay_center
  .progress_main
  .progress
  .progress-bar.fil-rouge {
  background: red;
}*/

.mescampagn_wraper .campagne_main::before {
  width: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.mescampagn_wraper .campagne_main:hover::before {
  width: 100%;
}
/* campagnes */
h1.bread_crumb a {
  color: #aeb3b6;
  float: left;
  width: auto;
}
h1.bread_crumb span {
  background: url(../images/breadcum-errow.svg) no-repeat left center;
  padding-left: 25px;
  margin-left: 20px;
}
.campagne_advance {
  float: right;
  padding-top: 10px;
}
.campagne_advance h6 {
  width: auto;
  margin-right: 15px;
}
.campagne_advance .progress {
  width: 125px;
  height: 5px;
}
.mesdata_list {
  float: left;
  width: 100%;
  padding-top: 30px;
}
.expoter {
  background: url(../images/ic-expoter.svg) no-repeat left center;
  float: right;
  width: auto;
  padding: 2px 0 2px 35px;
  margin-bottom: 25px;
  font-size: 14px;
  color: #273138;
  font-weight: 600;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  position: relative;
}
.expoter:hover {
  color: #8e54e9;
}
.mesdata_list ul li {
  float: left;
  width: 23.5%;
  margin: 15px 2% 0 0;
}
.mesdata_list ul li:nth-child(4n) {
  margin-right: 0;
}
.databox {
  background: #ffffff;
  float: left;
  width: 100%;
  padding: 15px 60px 15px 35px;
  min-height: 95px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
  position: relative;
}
.databox h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.databox h2 sup {
  font-size: 16px;
}
.databox h5 {
  font-weight: 500;
  color: #9ea5ad;
  margin-top: 0;
}
.databox .spanDatabox {
  width: 22px;
  position: absolute;
  right: 35px;
  top: 35px;
}
.databox > span {
  width: 22px;
  position: absolute;
  right: 35px;
  top: 35px;
}
.databox span img {
  width: 100%;
}
.mesdata_list ul.purple .databox h2 {
  color: #4679ea;
}
.mesdata_list ul.purple li:last-child .databox {
  background: #4679ea;
}
.mesdata_list li:last-child .databox h2,
.mesdata_list li:last-child .databox h5 {
  color: #ffffff !important;
}
.mesdata_list ul.orange .databox h2 {
  color: #efb30c;
}
.mesdata_list ul.orange li:last-child .databox {
  background: #efb30c;
}

.mesdata_list ul.purple .databox h2 {
  color: #8e54e9;
}

.mesdata_list ul.purple li:last-child .databox {
  background: #8e54e9;
}

.mesdata_list ul.green .databox h2 {
  color: #00cc47;
}
.mesdata_list ul.green li:last-child .databox {
  background: #00cc47;
}
.mescon_list {
  float: left;
  width: 100%;
  padding: 60px 0 0;
}
.mescon_list table {
  width: 100%;
}

.mescon_list thead {
  border-bottom: 20px solid #f6f8fa;
}
.mescon_list tr th {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: #93a1ad;
}
.mescon_list tr th i {
  display: inline-block;
  margin-left: 10px;
}
.mescon_list tbody tr {
  background: #ffffff;
  border-bottom: 3px solid #f6f8fa;
}
.mescon_list tbody tr:last-child {
  border: none;
}
.mescon_list tr td {
  padding: 15px 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 120%;
  color: #6e6e6e;
  vertical-align: middle;
}
.mescon_list tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.mescon_list tr td:nth-child(2) {
  text-transform: uppercase;
  color: #273138;
}
.mescon_list tr td:last-child {
  width: 25px;
  padding: 0;
}
.mescon_list tr td .number {
  width: 30px;
  height: 30px;
  margin: 2px 0 0 30px;
  line-height: 30px;
}
.mescon_list tr td .number.nbg {
  background: transparent;
}
.mescon_list tr td:last-child a {
  background: url(../images/sell-arrow.svg) no-repeat scroll right 10px center;
  display: block;
  height: 70px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  border-radius: 0 3px 3px 0;
}
.mescon_list tr td:last-child a:hover {
  background-position: right 5px center;
  background-color: #813ee6;
}

.overviwe_tab {
  float: right;
  width: auto;
}

.overviwe_tab ul li {
  margin-right: 80px;
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #9fa5ac;
  border-radius: 4px !important;
}
.overviwe_tab .nav-tabs li a {
  padding: 0;
  line-height: 30px;
}
.overviwe_tab .nav-tabs li a:before {
  display: none;
}
.overviwe_tab ul li span {
  left: inherit;
  right: -30px;
  top: 4px;
  font-size: 14px;
  color: #9fa5ac;
  font-weight: 600;
}
.overviwe_tab .nav-tabs li a svg {
  fill: #9fa5ac;
}
.overviwe_tab .nav-tabs li.active a svg {
  fill: #13d60f;
}
.overviwe_tab ul li.active span {
  color: #13d60f;
}

.overview_left {
  float: left;
  width: 36%;
}
.overview_left .overviwe_tab {
  padding-bottom: 30px;
}
.overview_left h1 {
  width: auto;
}
.overview_datatab {
  float: left;
  width: 100%;
  padding-top: 30px;
}
.overview_datatab .nav-tabs li {
  border: none;
  background: rgba(176, 176, 176, 0.15);
  margin: 6px 8px 0 0;
  border-radius: 2px;
}
.overview_datatab .nav-tabs li a {
  padding: 8px 10px;
  font-weight: 600;
}
.overview_datatab .nav-tabs li.active a {
  background: rgba(142, 84, 233, 0.15);
  color: #8e54e9;
}
.overview_datatab .nav-tabs li a:before {
  display: none;
}
.data_left {
  float: left;
  width: calc(40% - -16px);
  height: 350px;
  margin-top: 30px;
  overflow-y: scroll;
}
.data_left ul li {
  display: inline-block;
  background: #ffffff;
  width: 100%;
  padding: 22px 50px 22px 6%;
  margin-top: 4px;
  position: relative;
  z-index: 9;
}
.data_left ul li:before {
  background: #ffffff;
  position: absolute;
  left: -30%;
  top: 0;
  width: 30%;
  height: 100%;
  content: '';
  z-index: -9;
}
.data_left .number {
  background: #8e54e9;
  border-radius: 0;
  position: relative;
}
.data_left .number:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 0;
  border-color: transparent #7b49ca transparent transparent;
  content: '';
  position: absolute;
  right: 0;
  bottom: -8px;
}
.data_left h6 {
  margin: 5px 0 0 35px;
  width: auto;
  vertical-align: middle;
  font-weight: 500;
  color: #273138;
  text-transform: uppercase;
}
.data_left span.value {
  float: right;
  width: auto;
  color: #8e54e9;
  font-size: 15px;
  padding: 4px 0 0;
  font-weight: 500;
}
.map_right {
  float: right;
  width: calc(100% - -125px);
  height: 625px;
  position: absolute;
  right: -50%;
  top: -245px;
  z-index: 9;
}
#map {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
/* campagnes */

/*  sms campagnes */
.sms_head {
  background: #00cc47;
  float: left;
  width: 100%;
  padding: 15px 0;
  position: fixed;
  top: 80px;
  z-index: 9;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}

.smshead_title {
  float: left;
  width: 100%;
}
.smshead_title p {
  color: #ffffff;
  font-weight: 500;
}
.smshead_title span.value {
  float: none;
  padding: 0;
  display: inline-block;
  width: auto;
  font-size: 36px;
  color: #ffffff;
  font-weight: 600;
}
.sms_head .more_btn {
  background: url(../images/right-arrow.svg) no-repeat center rgba(255, 255, 255, 0.15);
  border: 2px solid #ffffff;
  float: right;
  width: 65px;
  height: 35px;
  margin-top: 15px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.sms_head .more_btn:hover {
  border-color: #fff;
  background-color: #009634;
  background-position: right 10px center;
}
.main.smswraper {
  margin-top: 205px;
}
.sms_credit {
  background: #e6dff7;
  float: right;
  width: auto;
  height: 55px;
  line-height: 50px;
  padding: 0 15px 0 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.sms_credit span {
  display: inline-block;
  font-size: 16px;
  color: #8e54e9;
  font-weight: 400;
  line-height: 120%;
  vertical-align: middle;
}
.sms_credit span.credit {
  margin-left: 15px;
  font-size: 24px;
  font-weight: 600;
}
.sms_credit span.credit sup {
  font-size: 12px;
}
.smswrap {
  float: left;
  width: 100%;
  padding-top: 40px;
}
.schedule_raw {
  background: #ffffff;
  float: none;
  display: inline-block;
  width: 100%;
  padding: 35px 105px;
  margin-top: 2px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.schedule_main {
  float: left;
  width: 100%;
}
.schedule_main.coman span {
  width: 100%;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: 400;
  line-height: 120%;
  display: inline-block;
  font-style: italic;
  padding: 10px 0;
}
.schedule_main.coman .info {
  float: left;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: 400;
  line-height: 120%;
  width: auto;
  font-style: italic;
  background: url(../images/ic-quesion.svg) no-repeat right top 10px;
  padding: 10px 30px 10px 0;
}
.schedule_l {
  display: inline-block;
  width: 20%;
  padding-right: 45px;
  vertical-align: middle;
}
.schedule_r {
  display: inline-block;
  width: 79%;
  vertical-align: middle;
}
.schedule_main .form-control {
  float: none;
  display: inline-block;
  padding: 0 20px;
  border: 1px solid #f4f4f4;
  height: 44px;
  color: #a4a4a4;
  font-weight: 400;
}
.schedule_main .form-control:focus {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.075);
  border-color: #8e54e9;
}
.schedule_main .form-control::-moz-placeholder {
  color: #a4a4a4;
}
.schedule_main .form-control::-webkit-input-placeholder {
  color: #a4a4a4;
}
.schedule_main .form-control::-ms-input-placeholder {
  color: #a4a4a4;
}
.schedule_main textarea.form-control {
  padding: 10px 20px;
  height: 135px;
}
.radiowrap {
  float: left;
  width: 100%;
}
.radiowrap ul li {
  float: left;
  width: 25%;
}
.radiowrap label {
  font-size: 16px;
  color: #6e6e6e;
}
.radiowrap span.ckeckname {
  font-size: 16px;
  color: #6e6e6e;
  margin-left: 25px;
}
input:checked ~ span.ckeckname {
  color: #00cc47;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 2px solid #6e6e6e;
  border-radius: 50%;
}
input:checked ~ .checkmark {
  border-color: #00cc47;
}
.checkmark:after {
  content: '';
  position: absolute;
}
input:checked ~ .checkmark:after {
  background: #00cc47;
}
.checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #6e6e6e;
}
.range-slider {
  float: left;
  width: 80%;
}
.range-slider span {
  float: left;
  width: auto;
  font-size: 16px;
  color: #6e6e6e;
}
.range-slider input {
  width: 80%;
  float: left;
  padding: 0 20px;
}
.range-slider.km_range {
  width: 38%;
  color: #6e6e6e;
}
.range-slider.km_range input {
  float: right;
  padding-left: 0;
}
.slider-selection {
  background: #bababa;
}
.schedule_r.location .radiowrap ul li {
  width: 33%;
  text-align: center;
}
.schedule_r.location .radiowrap span.ckeckname {
  float: left;
  width: 100%;
  margin: 12px 0 0;
}
.schedule_r.location .checkmark {
  margin: 0 auto;
  right: 0;
}
.form-control.loc_txt {
  background: url(../images/ic-location.svg) no-repeat left 18px center;
  padding: 0 15px 0 60px;
  margin-top: 35px;
}
.progrm_date .radiowrap ul li {
  padding-right: 40px;
}
.progrm_date .radiowrap label {
  margin-top: 12px;
}
.input-group .form-control {
  text-align: left;
  padding: 0 10px 0 50px;
  color: #273138;
}
.input-group.date {
  padding: 0;
}
.input-group.date .form-control {
  background: url(../images/ic-calendar.svg) no-repeat left 15px center;
}
.input-group #timepicker1 {
  background: url(../images/ic-time-clock.svg) no-repeat left 15px center;
}

.schedule_raw.bg {
  background: #00cc47;
}
.schedule_raw.bg label,
.schedule_raw.bg .schedule_main span {
  color: #ffffff;
}
.schedule_raw.bg .schedule_main.coman .info {
  background: url(../images/ic-quesion-white.svg) no-repeat right top 10px;
  color: #ffffff;
}
.form-control.small {
  float: left;
  width: 30%;
  margin-right: 15px;
}
.schedule_action {
  float: left;
  width: 100%;
  margin-top: 80px;
}
.schedule_action .btn-primary {
  background: transparent;
  height: 60px;
  line-height: 54px;
  border-radius: 2px;
  box-shadow: none;
  text-transform: inherit;
  font-size: 16px;
  border: 1px solid #dbdbdb;
  color: #a4a4a4;
  font-weight: 500;
  padding: 0 25px;
}
.schedule_action .btn-primary:hover,
.schedule_action .btn-primary:focus {
  background: #dbdbdb;
  color: #666;
}
.btn-primary.green {
  background: #00cc47;
  border-color: #00cc47;
  color: #ffffff !important;
}
.schedule_action .btn-primary.gry {
  background: #dbdbdb;
}
.btn-primary.green:hover,
.btn-primary.green:focus {
  background: #009634;
}
.datepicker table tr td.active.active,
.datepicker table tr td span.active.active {
  background: #9054f1 !important;
}

.schedule_main.proximi {
  padding-top: 25px;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #e2e2e2;
  border-radius: 25px;
  margin-top: 8px;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #e2e2e2;
  border-radius: 25px;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #e2e2e2;
  border-radius: 25px;
}
.js input[type='range']::-webkit-slider-runnable-track:after,
.js input[type='range'] /deep/ #track:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: #00cc47;
  content: '';
}
input[type='range']::-moz-range-progress {
  height: 35;
  background: #00cc47;
}
input[type='range']::-ms-fill-lower {
  height: 35;
  background: #00cc47;
}
input[type='range']::-webkit-range-progress {
  height: 35;
  background: #00cc47;
}
input[type='range']::-webkit-slider-runnable-track:before,
input[type='range'] /deep/ #track:before {
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  color: #b0b0b0;
  font: 0.78em/1.5 trebuchet ms, verdana, arial, sans-serif;
  white-space: nowrap;
  word-spacing: 22.28205em;
  content: '0% 100%';
}
input[type='range']::-webkit-slider-thumb {
  position: relative;
  z-index: 2;
  margin-top: -8px;
  width: 20px;
  height: 20px;
  background: #00cc47;
}
input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #00cc47;
}
input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #00cc47;
}
input[type='range']::-webkit-slider-thumb:after,
input[type='range'] /deep/ #thumb:after {
  position: absolute;
  right: 50%;
  bottom: 150%;
  width: 2.54545em;
  height: 3.27273em;
  -webkit-transform: translate(25%);
  transform: translate(25%);
  background: rgba(255, 255, 255, 0.5);
  color: #222;
  font: 900 0.6875em/2.86364em trebuchet ms, verdana, arial, sans-serif;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  -webkit-clip-path: polygon(
    75% 100%,
    0.72051em 2.2466em,
    0.27767em 1.91867em,
    0.03191em 1.4448em,
    0.03191em 0.91884em,
    0.27767em 0.44497em,
    0.72051em 0.11704em,
    1.27273em 0em,
    1.82494em 0.11704em,
    2.26779em 0.44497em,
    2.51354em 0.91884em,
    2.51354em 1.4448em,
    2.26779em 1.91867em,
    1.82494em 2.2466em
  );
  clip-path: polygon(
    75% 100%,
    0.72051em 2.2466em,
    0.27767em 1.91867em,
    0.03191em 1.4448em,
    0.03191em 0.91884em,
    0.27767em 0.44497em,
    0.72051em 0.11704em,
    1.27273em 0em,
    1.82494em 0.11704em,
    2.26779em 0.44497em,
    2.51354em 0.91884em,
    2.51354em 1.4448em,
    2.26779em 1.91867em,
    1.82494em 2.2466em
  );
}
.js input[type='range']::-webkit-slider-thumb:after,
.js input[type='range'] /deep/ #thumb:after {
  content: '100%';
}
input[type='range']:focus {
  outline: none;
  opacity: 0.99;
}
input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-runnable-track {
  position: relative;
  /* prep for creating rulers */
}
input[type='range']::-webkit-slider-runnable-track:before,
input[type='range']::-webkit-slider-runnable-track:after,
input[type='range'] /deep/ #track:before,
input[type='range'] /deep/ #track:after {
  position: absolute;
}
input[type='range']::-ms-track {
  border: none;
  /* remove default border */
  color: transparent;
  /* remove dfault tick marks */
}
input[type='range']::-webkit-slider-thumb {
  box-sizing: border-box;
  border: solid 0 transparent;
  border-radius: 50%;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  border: solid 0 transparent;
  border-radius: 50%;
  cursor: pointer;
}
input[type='range']::-ms-thumb {
  box-sizing: border-box;
  border: solid 0 transparent;
  border-radius: 50%;
  cursor: pointer;
}

/*  sms campagnes */

.creditwrap {
  background: #ffffff;
  margin: 30px 0 0;
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  overflow: hidden;
}
.creditwrap:before {
  background: #edf0f2;
  position: absolute;
  right: 0;
  top: 0;
  width: 34%;
  height: 100%;
  content: '';
}
.credit_left {
  float: left;
  width: 66%;
  padding: 60px 7% 100px;
}
.smstitle {
  float: left;
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
  border-bottom: 1px solid #d8d8d8;
}
.smstitle h5 {
  color: #273138;
  font-weight: 500;
}
.smstitle .value {
  padding: 0;
  font-size: 60px;
  color: #00cc47;
}
.credit_raw {
  float: left;
  width: 100%;
  padding: 35px 0;
  border-bottom: 1px solid #d8d8d8;
}
.credit_list {
  float: left;
  width: 100%;
}
.credit_raw.last {
  padding: 20px 0;
}
.credit_raw ul li {
  float: left;
  width: 100%;
  margin: 15px 0 0;
  padding-right: 20%;
  position: relative;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  line-height: 120%;
}
.credit_list li:first-child {
  margin-top: 0;
}
.credit_raw ul li span {
  position: absolute;
  right: 0;
  top: 0;
}

.credit_box {
  background: #f1eafc;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  padding: 20px 25px 20px 50px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.credit_box:before {
  background: url(../images/ic-ectangle.svg) no-repeat center;
  width: 45px;
  height: 45px;
  left: 0;
  top: 0;
  content: '';
  position: absolute;
}
.credit_box h5 {
  width: 65%;
  float: none;
  padding-top: 8px;
  display: inline-block;
  font-weight: 500;
  color: #8e54e9;
}
.credit_left .credit_box .btn-primary {
  float: right;
  height: 40px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 25px;
  color: #ffffff;
  min-width: inherit;
  border: none;
  box-shadow: none;
  border-radius: 3px;
  text-transform: inherit;
}
.credit_left p {
  padding-top: 25px;
  font-style: italic;
}
.credit_left .btn-primary {
  min-width: 230px;
  text-align: center;
}
.credit_summery {
  float: right;
  width: 34%;
  padding: 60px 5%;
  position: relative;
}
.summery_head {
  float: left;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}
.summery_head h5 {
  float: left;
  width: auto;
  color: #273138;
}
.summery_head a {
  float: right;
  width: auto;
  font-size: 16px;
  line-height: 120%;
}
.summery_list {
  padding-top: 20px;
}
.summery_list ul li {
  float: right;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.summery_list label {
  width: 30%;
  padding-right: 10px;
  font-size: 14px;
  color: #93a1ad;
  line-height: 140%;
}
.summery_list p {
  float: right;
  width: 70%;
  color: #000000;
  line-height: 140%;
  text-align: right;
}
.packwrap {
  margin-top: 30px;
  text-align: center;
}
.packwrap .pack_main {
  width: 64%;
  display: inline-block;
}
.packwrap .credit_left {
  width: 100%;
  background: #ffffff;
  padding: 60px 7% 10px;
  text-align: left;
}
.packwrap .credit_raw {
  border: none;
}
.pack_list {
  float: left;
  width: 100%;
  margin-top: 10px;
}
.pack_box {
  background: #ffffff;
  border-top: 3px solid #d7cde5;
  display: inline-block;
  width: 100%;
  padding: 0 32px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  margin-top: 15px;
  vertical-align: middle;
  text-align: left;
  line-height: 120px;
}
.pack_l {
  width: 49%;
  display: inline-block;
  vertical-align: middle;
}
.pack_name {
  float: left;
  width: 50%;
  padding-right: 20px;
}
.pack_name h5 {
  color: #000;
  text-transform: capitalize;
  margin-top: 5px;
}
.pack_name span.recommend {
  background: url(../images/recommended-star.svg) no-repeat left 10px center;
  float: left;
  width: auto;
  font-size: 14px;
  color: #545454;
  font-weight: 400;
  padding-left: 30px;
  line-height: 120%;
}
.pack_l .no_sms.green {
  background: url(../images/sms-pack-green.svg) no-repeat left top 5px;
}
.pack_l .no_sms {
  background: url(../images/sms-pack.svg) no-repeat left top 5px;
  float: left;
  width: 50%;
  padding-left: 50px;
  font-size: 36px;
  color: #8e54e9;
  font-weight: normal;
  line-height: 100%;
}
.pack_r {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
.pack_price {
  float: left;
  width: 68%;
  padding-right: 25px;
  text-align: right;
  line-height: 120%;
}
.pack_price h4 {
  color: #6e6e6e;
  font-weight: 400;
}
.pack_price span {
  font-size: 14px;
  color: #8b8b8b;
}
.pack_r a {
  float: right;
  width: auto;
  padding: 0 25px;
  margin-top: 8px;
  border: 1px solid #5b5b5b;
  height: 36px;
  font-size: 13px;
  color: #5b5b5b;
  line-height: 34px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}
.pack_r a:hover {
  border-color: #8e54e9;
  color: #8e54e9;
}

/* cart start */
.validation_wrap {
  padding-top: 25px;
}
.cartwraper .btn-primary,
.payment_wrap .btn-primary {
  float: right;
  min-width: 220px;
  margin-top: 25px;
  text-align: center;
}
.basketwrap {
  float: left;
  width: 100%;
  margin-top: 15px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.baskethead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 100%;
  padding: 20px 35px;
  position: relative;
}
.baskethead h5 {
  width: auto;
  padding-right: 30px;
  font-weight: 500;
}
.cart_emil {
  float: right;
  width: auto;
  margin: 1px 45px 0 0;
  font-size: 14px;
  line-height: 120%;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
}

.appear {
  opacity: 1;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
}

.disapear {
  opacity: 0;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
}
.hidebasket {
  float: right;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  right: 35px;
  cursor: pointer;
}
.basketbody {
  float: left;
  width: 100%;
  padding: 15px 35px 5px;
}
.basketdata {
  float: left;
  width: 100%;
}
.basketdata table {
  width: 100%;
}
.basketdata table thead td {
  color: #959595;
  text-transform: uppercase;
  font-size: 12px;
}
.basketdata table tbody tr:last-child {
  border: none;
}
.basketdata table tbody tr {
  border-bottom: 1px solid #d1d1d1;
}
.basketdata table tbody td {
  padding: 15px 0;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
}
.cart_avtar {
  float: left;
  width: 32px;
  height: 32px;
}
.cart_avtar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.basketdata .item_name {
  width: auto;
  color: #273138;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}
.item_name a.item_info {
  display: inline-block;
  margin: 0 0 0 10px;
  width: 20px;
  height: 20px;
  background: url(../images/item-info.svg) no-repeat center;
  position: relative;
  top: 4px;
}
.basketdata .quantity_count {
  padding: 0;
  margin: 0;
  border: none;
  width: auto;
}
.basketdata .btn-number {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.recipients_box {
  background: #ffffff;
  padding: 30px 10%;
  margin-top: 15px;
  overflow: hidden;
  position: relative;
}
.alertbox {
  background: #00cc47;
  float: left;
  width: 80%;
  padding: 16px 20px;
  margin: 0 10%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  transform: translateY(0);
}
.alertbox.deleted {
  background: #eb5757;
}
.alertbox.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-30px);
}
.alertbox p {
  width: auto;
  color: #ffffff;
  line-height: 120%;
}
.alertbox span {
  float: right;
  width: auto;
  margin-top: 2px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 120%;
  text-transform: uppercase;
}
.recipients_head {
  float: left;
  width: 100%;
}
.recipients_head h6,
.recipi_det h6 {
  margin-bottom: 3px;
  font-size: 16px;
  color: #000;
}
.recipients_head p {
  font-size: 14px;
}
.recipi_search {
  float: left;
  width: 100%;
  margin-top: 25px;
  position: relative;
}
.recipi_search .form-control {
  border: 1px solid #d9d9d9;
  height: 44px;
  padding: 0 60px 0 20px;
  color: #000;
  font-size: 14px;
}
.recipi_search .form-control:focus {
  border-color: #8e54e9;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.075);
  font-style: inherit;
}
.recipi_search .form-control::-moz-placeholder {
  color: #a4a4a4;
  font-style: italic;
}
.recipi_search .form-control::-webkit-input-placeholder {
  color: #a4a4a4;
  font-style: italic;
}
.recipi_search .form-control::-ms-input-placeholder {
  color: #a4a4a4;
  font-style: italic;
}
.resrt_btn {
  background: url(../images/ic-close.svg) no-repeat center;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 25px;
  height: 25px;
}
.search_list {
  float: left;
  max-height: 400px;
  margin-top: 15px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.search_list ul li {
  float: left;
  width: 100%;
  padding: 25px 0;
  border-bottom: 1px solid #9b9b9b;
}
.search_list ul li:last-child {
  border-bottom: none;
}
.recipi_det {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 20px;
}
.recipi_det .recipi_add {
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 8px;
  width: 24px;
  height: 25px;
}
.recipi_det .recipi_add[disabled] {
  opacity: 0.5;
}
.recipi_det p {
  color: #959595;
}
/* cart end */

/* payment */

.payment_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment_wrap {
  padding-top: 50px;
}
.payment_option {
  float: left;
  width: 100%;
}
.payment_option label {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  float: left;
  width: 100%;
  height: 90px;
  line-height: 90px;
  padding: 0 35px;
  cursor: pointer;
  font-weight: 500;
  color: #6e6e6e;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin-top: 10px;
}
.payment_option .payment_img {
  position: absolute;
  right: 35px;
  top: 10px;
  width: 178px;
}
.payment_option .ckeckname {
  margin-left: 25px;
}
.payment_option input[type='radio'] {
  vertical-align: middle;
  margin: 0;
}
.payment_option .checkmark::after {
  background: transparent;
}
.payment_option .checkmark {
  left: 33px;
  top: 35px;
  border-color: #b0b0b0;
}
.payment_main .credit_summery {
  background: #edf0f2;
  width: 100%;
  padding: 30px 40px;
}
.payment_main .panel-default {
  background: #edf0f2;
  padding: 20px 0;
  border-bottom: 1px solid #adb5bd;
}
.payment_main .panel-group .panel {
  border-radius: 0;
}
.payment_main .panel-group .panel + .panel {
  margin: 0;
}
.payment_main .panel-title a {
  padding: 5px 0 0;
  background: url(../images/payment-arrow-up.svg) no-repeat right center;
}
.payment_main .panel-title a.collapsed {
  background: url(../images/payment-arrow.svg) no-repeat right center;
}
.item_summery {
  float: left;
  width: 100%;
  line-height: 80px;
}
.item_avtar {
  float: left;
  width: 85px;
  height: 85px;
}
.item_avtar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item_det {
  display: inline-block;
  width: 70%;
  padding: 0 20px;
  vertical-align: middle;
}
.payment_main .item_det h6 {
  text-transform: uppercase;
  font-size: 12px;
}
.payment_main .item_det p {
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}
.payment_main .item_det span {
  background: none;
  float: left;
  width: 100%;
  padding-top: 5px;
  position: relative;
  left: 0;
  top: 0;
  color: #4c4c4c;
  line-height: 120%;
}
.payment_main .panel-title a.collapsed span {
  background: none;
}
.payment_main .panel-body {
  background: #f7fafc;
  padding: 8px;
}
.payment_main .panel-body p {
  font-style: italic;
  color: #4c4c4c;
  font-size: 14px;
}
.payment_main .summery_list {
  padding-top: 5px;
}
.payment_main .summery_list ul li {
  margin-top: 8px;
}
.payment_main .summery_list label {
  color: #4c4c4c;
}
.payment_main .summery_list p {
  padding: 0;
  font-weight: 600;
  color: #4c4c4c;
  font-style: inherit;
}

.total_summry {
  float: left;
  width: 100%;
}
.total_summry .summery_list {
  padding: 15px 0 20px;
  border-bottom: 1px solid #adb5bd;
}
.total_summry .summery_list:last-child {
  padding: 10px 0 20px;
  border-bottom: none;
}

#regions > div {
  display: inline-block;
  float: none;
  width: auto !important;
  height: auto !important;
}
.country_chart {
  float: none;
  display: inline-block;
  width: 60%;
  height: 750px;
  padding: 0;
}
.graph {
  padding-top: 80px;
}
#update-nav {
  width: 100%;
  height: 30px;
}
#range-selector {
  width: 50%;
  float: left;
}
#date-selector {
  width: 50%;
  float: right;
}
#piechart {
  position: relative;
}
#piechart div {
  width: 100% !important;
  height: 100px !important;
  position: relative;
}
#piechart div svg {
  height: 100px !important;
  width: 330px;
}
#piechart div svg rect {
  height: 100px !important;
  position: relative !important;
  display: none;
}
#piechart:before,
#piechart:after {
  position: absolute;
  left: 20px;
  top: 0;
  width: 15px;
  height: 15px;
  content: '';
}
#piechart:before {
  background: url(../images/ic-male.svg) no-repeat center;
  left: 0px;
}
#piechart:after {
  background: url(../images/ic-female.svg) no-repeat center;
  left: 310px;
}

.instru_model .modal {
  overflow: hidden;
}
.instru_model .modal.fade .modal-dialog {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
}
.instru_model .modal-content {
  background: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
.instru_model .modal-dialog {
  width: 100%;
  text-align: center;
  margin: 120px 0 0;
}
.modal-open .modal {
  padding: 0 !important;
}
.instru_model #myModal3 .modal-dialog {
  margin: 600px 0 0;
}
#myModal1 .tooltip_wrap {
  margin-right: 260px;
}
#myModal3 .tooltip_wrap {
  width: 400px;
  margin-right: 150px;
}
#myModal3 .tooltip_con h6 {
  color: #2d9cdb;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
}

/* Visiperf */

.payment_option label[disabled] {
  background: #f4f4f4;
  cursor: default;
}

.payment_option label[disabled]:hover {
  border-color: #d9d9d9;
}

.payment_option label[disabled] .ckeckname {
  color: #c0c0c0;
}

.payment_option label[disabled] .checkmark {
  background: #f4f4f4;
  border-color: #d0d0d0;
}

.payment_option label[disabled] .payment_img img {
  filter: grayscale(100%);
  opacity: 0.7;
}

.item_det span span span {
  width: auto !important;
  padding-top: 0 !important;
}

.payment_form {
  display: flow-root;
}

.payment_form div.form-group {
  padding: 0;
}

.payment_form div.form-group label {
  margin: 12px 0;
  color: #6e6e6e;
  font-size: 14px;
}

.payment_form div.form-group .form-control {
  border: 1px solid #d9d9d9;
}

/* Reset Password section */
.reset-wraper {
  height: 100vh;
  position: relative;
}

.reset-wrap {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.reset-main {
  display: inline-block;
  width: 60%;
  padding: 50px;
}
.reset-main .logo {
  display: inline-block;
  width: 170px;
}
.reset-main .logo a {
  margin: 0;
}
.reset-main h1 {
  padding-top: 20px;
  color: #273138;
  font-size: 28px;
}
.reset-main p {
  float: none;
  display: inline-block;
  padding-top: 20px;
  color: #000;
  font-size: 16px;
}
.reset-main .form-main .form-control {
  height: 56px;
}
.reset-main .form-main .btn-primary {
  margin-top: 10px;
}
.reset-main a {
  display: inline-block;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}
.reset-main a:hover {
  text-decoration: underline;
}

.reset-main.not-recevie {
  background: #fff;
}

.notrecvie-raw {
  float: left;
  width: 100%;
  margin-top: 10px;
  position: relative;
  padding-left: 40%;
  text-align: left;
}
.notrecvie-raw label {
  width: 35%;
  margin-right: 5%;
  padding: 0;
  position: absolute;
  left: 0;
  text-align: right;
  top: 0;
}
.notrecvie-raw .form-control {
  background: #f8f8f8;
}

.markdown * {
  float: initial;
  list-style: inherit;
}

.markdown ol {
  list-style: decimal;
}

.markdown ul {
  list-style: disc;
}

.markdown ul ul {
  list-style: circle;
  list-style-position: inside;
}

.markdown li {
  display: list-item;
}

.markdown ul p {
  display: inline;
}

.markdown p {
  color: inherit;
}

.markdown table {
  margin-top: 5px;
}

.markdown th {
  font-weight: bold;
  white-space: nowrap;
}

.markdown th,
.markdown td {
  border: 1px solid #000;
  padding: 5px 7px;
}
